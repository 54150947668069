@font-face {
  font-family: 'VolmerSansWeb-Light';
  font-style: normal;
  font-weight: 200;
  src: url("/assets/fonts/VolmerSansWeb-Light.eot");
  src: local(""), url("/assets/fonts/VolmerSansWeb-Light.eot?#iefix") format("embedded-opentype") url("/assets/fonts/VolmerSansWeb-Light.woff2") format("woff2"), url("/assets/fonts/VolmerSansWeb-Light.woff") format("woff"), url("/assets/fonts/VolmerSansWeb-Light.ttf") format("truetype"), url("/assets/fonts/VolmerSansWeb-Light.svg#Poppins") format("svg"); }

@font-face {
  font-family: 'VolmerSansWeb-Regular';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/VolmerSansWeb-Regular.eot");
  src: local(""), url("/assets/fonts/VolmerSansWeb-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/VolmerSansWeb-Regular.woff2") format("woff2"), url("/assets/fonts/VolmerSansWeb-Regular.woff") format("woff"), url("/assets/fonts/VolmerSansWeb-Regular.ttf") format("truetype"), url("/assets/fonts/VolmerSansWeb-Regular.svg#Poppins") format("svg"); }

@font-face {
  font-family: 'VolmerSansWeb-Bold';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/VolmerSansWeb-Bold.eot");
  src: local(""), url("/assets/fonts/VolmerSansWeb-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/VolmerSansWeb-Bold.woff2") format("woff2"), url("/assets/fonts/VolmerSansWeb-Bold.woff") format("woff"), url("/assets/fonts/VolmerSansWeb-Bold.ttf") format("truetype"), url("/assets/fonts/VolmerSansWeb-Bold.svg#Poppins") format("svg"); }

* {
  font-family: 'VolmerSansWeb-Regular', sans-serif;
  font-size: calc(10px + 10%); }

body {
  color: #29408e; }

p, a, li, b {
  font-size: 1.5rem; }

a {
  color: #29408e; }
  a:hover {
    color: #29408e; }

li {
  margin-bottom: 5px; }

h1 {
  font-size: 4em;
  margin-bottom: 30px; }

h3 {
  font-size: 2em;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    h3 {
      font-size: 3em;
      margin-bottom: 30px; } }

h4 {
  font-size: 2.2em;
  margin-bottom: 30px; }

h5 {
  font-size: 1.8em;
  /*margin-bottom: 30px*/ }

strong, b, h1, h2, h3, h4, h5, h6 {
  font-family: 'VolmerSansWeb-Bold', sans-serif;
  font-weight: 700; }

h2 {
  font-size: 4em;
  margin-bottom: 20px !important; }
  @media (max-width: 1024px) {
    h2 {
      margin: 0 auto 3rem !important;
      text-align: center !important; } }
  h2 + h5 {
    margin: 0rem 0rem 1.5rem !important; }
    @media (max-width: 1024px) {
      h2 + h5 {
        margin: 0rem auto 1.5rem !important;
        text-align: center !important; } }
    @media (max-width: 1024px) {
      h2 + h5 + p {
        text-align: center !important; } }

@media (max-width: 768px) {
  h4, h5 {
    text-align: center !important; } }

.glasses {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 210px;
  max-height: 100%;
  overflow-y: scroll; }
  .glasses #noresults {
    display: none;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    background: white;
    margin: 30vh 15px 15px 15px; }
    .glasses #noresults h3 {
      color: #ccc; }
  .glasses .brille {
    height: 0;
    min-width: 30%;
    max-width: 50%;
    padding-bottom: 23%;
    border: 1px solid #f0f0f0;
    margin: 1%;
    background: white;
    cursor: pointer;
    align-self: flex-start;
    display: none;
    overflow: hidden; }
    .glasses .brille.active {
      display: block; }
    .glasses .brille .thumb {
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .glasses .detail {
    position: sticky;
    padding: 0 30px;
    overflow: hidden;
    background: white;
    bottom: 20px;
    z-index: 99999999999999;
    margin: 20px;
    display: none;
    background: white;
    width: 100%; }
    .glasses .detail table {
      position: absolute;
      bottom: 30px;
      left: 30px; }
    .glasses .detail td {
      padding: 0 10px 10px 10px;
      text-align: left; }
    .glasses .detail.active {
      border: 1px solid #7790CB;
      height: 600px;
      max-height: 60vw;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; }
      .glasses .detail.active .close {
        position: absolute;
        right: 20px;
        top: 20px;
        display: inline-block;
        cursor: pointer;
        z-index: 999; }
    .glasses .detail img {
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: 0; }
  @media (max-width: 600px) {
    .glasses {
      padding-left: 0px;
      display: flex;
      text-align: center;
      align-items: flex-start; }
      .glasses .brille {
        flex: 1 0 0;
        min-width: 150px;
        max-width: 50%;
        height: 150px;
        border: 1px solid #f0f0f0;
        margin: 5px 0 0 5px;
        background: white;
        cursor: pointer; }
      .glasses .detail {
        position: fixed;
        left: 0px;
        top: 50px;
        right: 0px;
        bottom: auto;
        width: auto;
        max-height: 100vw !important; } }

#filterMenuMobile {
  display: none; }
  @media (max-width: 600px) {
    #filterMenuMobile {
      display: block;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f0f0f0; }
      #filterMenuMobile select {
        margin: 5px;
        font-size: 0.925em;
        border: none;
        border-radius: none;
        outline: none;
        padding: 5px;
        background: white; } }

#filterMenu {
  display: block;
  width: 200px;
  background: #f0f0f0;
  padding: 20px 15px;
  height: 100%;
  z-index: 9999999999999;
  position: absolute;
  left: 0;
  overflow-y: auto; }
  #filterMenu h3 {
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: 100;
    padding-bottom: 5px;
    color: #333;
    border-bottom: 1px solid #7790CB;
    max-width: 100% !important;
    text-align: right; }
  #filterMenu a {
    font-size: 1em;
    text-align: right;
    display: block;
    padding: 3px 0;
    color: #666;
    position: relative;
    cursor: pointer; }
    #filterMenu a:before {
      content: "\203A";
      position: relative;
      color: #7790CB;
      left: -15px;
      font-size: 1.1em;
      opacity: 0;
      transition: all 300ms ease; }
    #filterMenu a.active {
      color: #7790CB; }
      #filterMenu a.active:before {
        opacity: 1;
        content: "\203A";
        position: relative;
        color: #7790CB;
        left: -5px;
        font-size: 1.1em; }
  @media (max-width: 600px) {
    #filterMenu {
      display: none; } }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  position: relative; }

body {
  background: #F5F5F5;
  color: #333; }

p {
  line-height: 21px;
  margin-bottom: 15px; }

.noPadding {
  padding: 0rem; }

.noPadding_ltr {
  padding: 0rem 0rem 0px 0rem !important; }

img {
  max-width: 100%; }

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }

.menuShowed {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
  transition: all .3s ease; }

.activePage {
  text-decoration: underline; }

.lilaSection {
  background-color: #29408e !important; }
  .lilaSection * {
    color: #ffffff !important; }

.bgImage {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }

.bgImage_right {
  background-position: right !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }

#carouselProduct {
  margin: 2rem 0rem 4rem; }
  #carouselProduct .owl-item .item img {
    float: right;
    margin: 4rem 0rem 0rem; }
    @media (max-width: 1024px) {
      #carouselProduct .owl-item .item img {
        float: none;
        margin: 4rem auto 0rem; } }
  #carouselProduct .owl-dots {
    position: absolute;
    top: 0px;
    right: 0px; }
    @media (max-width: 1024px) {
      #carouselProduct .owl-dots {
        bottom: -35px !important;
        top: auto;
        left: 0px;
        right: 0px;
        margin: auto !important; } }
    #carouselProduct .owl-dots .owl-dot {
      display: inline-block; }
      #carouselProduct .owl-dots .owl-dot span {
        background: #29408e !important;
        border: 2px solid #29408e;
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0rem 1rem; }
        #carouselProduct .owl-dots .owl-dot span:last-child {
          margin-right: 0rem !important; }
      #carouselProduct .owl-dots .owl-dot.active span {
        background-color: white !important;
        border: 2px solid #29408e; }
      #carouselProduct .owl-dots .owl-dot:focus {
        outline: none !important; }
        #carouselProduct .owl-dots .owl-dot:focus span {
          outline: none !important; }

.seeOnline_tool {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute; }
  .seeOnline_tool a {
    position: absolute;
    border: 1px solid #29408e;
    background: #29408e;
    z-index: 999999999;
    top: 30%;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
    display: table;
    transform: translateY(-30%);
    padding: 20px 20px;
    font-size: 1.5em;
    color: white;
    transition: all 0.2s linear;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .seeOnline_tool a {
        padding: 12px 12px;
        font-size: 1.1em; } }
    .seeOnline_tool a:hover {
      transition: all 0.2s linear;
      border: 1px solid white;
      background: transparent; }

.seeOnline_tool_middle {
  width: 100%;
  height: calc(100% - 61px);
  left: 0px;
  top: 0px;
  position: absolute; }
  .seeOnline_tool_middle a {
    position: absolute;
    border: 1px solid #29408e;
    background: #29408e;
    z-index: 999999999;
    top: 50%;
    right: 0px;
    left: 0px;
    bottom: 0px;
    margin: auto;
    display: table;
    transform: translateY(-50%);
    padding: 20px 20px;
    font-size: 1.5em;
    color: white;
    transition: all 0.2s linear;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .seeOnline_tool_middle a {
        padding: 12px 12px;
        font-size: 1.1em; } }
    .seeOnline_tool_middle a:hover {
      transition: all 0.2s linear;
      border: 1px solid white;
      background: transparent; }

#videoIframe_scription {
  height: 60vh; }

@media (max-width: 1024px) {
  #aktion_section .text-centered-mobil {
    text-align: center !important; }
  #aktion_section .buttonCentered {
    margin: 0 auto !important; } }

.aktion_wrap {
  display: flex !important;
  flex-wrap: wrap !important; }
  @media (max-width: 1024px) {
    .aktion_wrap > div:first-child {
      order: 2; } }

.button {
  padding: 20px 10px;
  font-size: 1.3em; }

body .button_alone {
  max-width: 200px;
  text-align: center; }

body .button_alone a {
  margin: 0 !important;
  width: 100% !important; }

.text-start-align li, .text-start-align p {
  text-align: left !important;
  list-style: none !important; }

.text-start-align h1, .text-start-align h3 {
  text-align: left !important; }

.lilaButton {
  background: #29408e !important;
  color: #fff !important; }

#navigation {
  display: flex; }

.text-center {
  text-align: center; }

@media (max-width: 1024px) {
  #aktion_section .text-centered-mobil {
    text-align: center !important; }
  #aktion_section .buttonCentered {
    margin: 0 auto !important; } }

.text-start-align {
  justify-content: flex-start !important; }
  .text-start-align p {
    max-width: 100% !important; }
  .text-start-align p, .text-start-align li {
    text-align: left !important;
    list-style: none !important; }
  .text-start-align strong {
    font-size: 1.6rem !important; }
  .text-start-align h1, .text-start-align h3 {
    text-align: left !important; }
  .text-start-align ul {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 15px !important; }
    .text-start-align ul li {
      margin-bottom: 8px;
      list-style-type: disc !important; }

/*DIGITALER SEHSTRESS SEITE*/
.digitaler-sehstress .gridContainer .gridItem p, .myopie .gridContainer .gridItem p, .sehstress-bei-kindern .gridContainer .gridItem p {
  max-width: 100% !important; }

.digitaler-sehstress footer, .myopie footer, .sehstress-bei-kindern footer {
  display: flex !important; }

.digitaler-sehstress .blauZitat, .myopie .blauZitat, .sehstress-bei-kindern .blauZitat {
  font-size: 3rem !important;
  font-weight: bold !important;
  line-height: 1.4 !important;
  color: #29408e !important;
  position: relative; }
  .digitaler-sehstress .blauZitat:before, .myopie .blauZitat:before, .sehstress-bei-kindern .blauZitat:before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    left: -16px;
    top: -10px;
    background: url("/assets/img/zitat_top.png");
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important; }
  .digitaler-sehstress .blauZitat span, .myopie .blauZitat span, .sehstress-bei-kindern .blauZitat span {
    height: 40px;
    width: 40px;
    position: relative; }
    .digitaler-sehstress .blauZitat span:after, .myopie .blauZitat span:after, .sehstress-bei-kindern .blauZitat span:after {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      right: -20px;
      top: -10px;
      background: url("/assets/img/zitat_bottom.png");
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important; }

.digitaler-sehstress .gridItem.text-left, .myopie .gridItem.text-left, .sehstress-bei-kindern .gridItem.text-left {
  align-items: center !important;
  justify-content: center !important; }
  .digitaler-sehstress .gridItem.text-left *, .myopie .gridItem.text-left *, .sehstress-bei-kindern .gridItem.text-left * {
    text-align: left !important; }

.digitaler-sehstress .main_container, .myopie .main_container, .sehstress-bei-kindern .main_container {
  max-width: 1640px !important;
  margin: 0 auto !important; }
  .digitaler-sehstress .main_container .headerbild-widht-imgBG, .myopie .main_container .headerbild-widht-imgBG, .sehstress-bei-kindern .main_container .headerbild-widht-imgBG {
    padding-top: 15%;
    padding-bottom: 15%;
    min-height: 600px;
    max-height: 40vw !important; }
    @media (min-width: 992px) and (max-width: 1590px) {
      .digitaler-sehstress .main_container .headerbild-widht-imgBG, .myopie .main_container .headerbild-widht-imgBG, .sehstress-bei-kindern .main_container .headerbild-widht-imgBG {
        min-height: 670px !important; } }
    @media (max-width: 991px) {
      .digitaler-sehstress .main_container .headerbild-widht-imgBG, .myopie .main_container .headerbild-widht-imgBG, .sehstress-bei-kindern .main_container .headerbild-widht-imgBG {
        padding-top: 50px;
        padding-bottom: 50px;
        max-height: inherit !important;
        min-height: auto !important; } }
  @media (min-width: 992px) {
    .digitaler-sehstress .main_container .digitaler-sehstress-headerbild, .myopie .main_container .digitaler-sehstress-headerbild, .sehstress-bei-kindern .main_container .digitaler-sehstress-headerbild {
      background: url("/assets/img/digitaler-sehstress/digitaler-sehstress-headerbild.jpg");
      background-size: cover !important;
      background-position: right center !important;
      background-repeat: no-repeat !important; } }
  .digitaler-sehstress .main_container h1, .digitaler-sehstress .main_container h2, .digitaler-sehstress .main_container h3, .myopie .main_container h1, .myopie .main_container h2, .myopie .main_container h3, .sehstress-bei-kindern .main_container h1, .sehstress-bei-kindern .main_container h2, .sehstress-bei-kindern .main_container h3 {
    font-weight: bold;
    font-size: 3em !important;
    line-height: 1.4 !important; }
  .digitaler-sehstress .main_container .blueBox_floatingRight, .myopie .main_container .blueBox_floatingRight, .sehstress-bei-kindern .main_container .blueBox_floatingRight {
    background: #29408e !important;
    max-width: 300px;
    padding: 3rem;
    align-self: center; }
    .digitaler-sehstress .main_container .blueBox_floatingRight *, .myopie .main_container .blueBox_floatingRight *, .sehstress-bei-kindern .main_container .blueBox_floatingRight * {
      color: white; }

.headerbild-widht-imgBG {
  padding-top: 15%;
  padding-bottom: 15%; }
  @media (max-width: 991px) {
    .headerbild-widht-imgBG {
      padding-top: 50px;
      padding-bottom: 50px; } }

@media (min-width: 992px) {
  .myopie-headerbild {
    background: url("/assets/img/digitaler-sehstress/myopie-headerbild.jpg");
    background-size: cover !important;
    background-position: right center !important;
    background-repeat: no-repeat !important; } }

@media (min-width: 992px) {
  .sehstress-bei-kindern-headerbild {
    background: url("/assets/img/digitaler-sehstress/sehstress-bei-kindern-headerbild.jpg");
    background-size: cover !important;
    background-position: right center !important;
    background-repeat: no-repeat !important; } }

.nav__item-cta {
  position: relative;
  margin: 0 0 0 30px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .05em;
  display: inline-block;
  top: 33px; }
  @media (max-width: 991px) {
    .nav__item-cta {
      display: none !important; } }
  .nav__item-cta .tel_jens {
    padding: 5px 10px;
    background-color: #29408e;
    color: #fff !important; }

@media (min-width: 992px) {
  .telBtn {
    display: none !important; } }

.tel_jens * {
  color: white !important; }

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  background: #004189;
  z-index: 20;
  opacity: 0.6; }

nav {
  width: 100%;
  max-width: 1640px;
  z-index: 999999;
  position: relative;
  background: #ffffff;
  padding: 10px 40px;
  display: flex;
  margin: 0 auto;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  align-items: center; }
  nav #logo {
    width: 30%;
    z-index: 999999;
    max-width: 170px;
    position: relative; }
    @media (max-width: 991px) {
      nav #logo {
        width: 80%; } }
    nav #logo img {
      max-width: 180px; }
      @media (max-width: 480px) {
        nav #logo img {
          max-width: 120px; } }
  nav #mainMenu {
    width: 100%;
    right: 40px;
    text-align: right;
    position: absolute;
    height: 100%; }
    @media (max-width: 991px) {
      nav #mainMenu {
        top: 100%;
        left: 0;
        right: 0;
        height: auto;
        background: #ffffff;
        display: none;
        visibility: hidden;
        opacity: 0;
        padding: 1rem 0rem;
        overflow-y: scroll;
        max-height: 70vh; } }
    nav #mainMenu .nav__item, nav #mainMenu .nav__item-cta {
      position: relative;
      margin: 0 0 0 30px;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      display: inline-block;
      top: 33px; }
      @media (max-width: 767px) {
        nav #mainMenu .nav__item, nav #mainMenu .nav__item-cta {
          font-size: 2.3rem; } }
      @media (min-width: 768px) and (max-width: 991px) {
        nav #mainMenu .nav__item, nav #mainMenu .nav__item-cta {
          font-size: 3rem; } }
      @media (max-width: 991px) {
        nav #mainMenu .nav__item, nav #mainMenu .nav__item-cta {
          display: block;
          text-align: left;
          top: 0;
          padding: 0 40px 0 40px;
          margin: 1rem 0rem; } }
      nav #mainMenu .nav__item:hover, nav #mainMenu .nav__item-cta:hover {
        text-decoration: underline; }
    nav #mainMenu .nav__item-submenu {
      display: none;
      width: 100%;
      left: 40px;
      top: 100%;
      background: #ffffff;
      padding: 0px;
      position: absolute;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0);
      -webkit-box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
      box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15); }
      @media (max-width: 991px) {
        nav #mainMenu .nav__item-submenu {
          display: none;
          position: relative;
          left: 0px;
          top: 0;
          padding: 0px 40px;
          background: #ffffff;
          box-shadow: none !important; } }
      nav #mainMenu .nav__item-submenu .bgImageMenu {
        width: 50%;
        float: left;
        line-height: 0px; }
        @media (max-width: 991px) {
          nav #mainMenu .nav__item-submenu .bgImageMenu {
            display: none; } }
      nav #mainMenu .nav__item-submenu .subList_menu {
        width: 50%;
        float: left;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0px 0px 0px 120px; }
        @media (max-width: 991px) {
          nav #mainMenu .nav__item-submenu .subList_menu {
            float: none;
            padding: 0px !important;
            top: 0px;
            transform: none !important;
            position: relative;
            padding: 1rem 0rem 1.5rem 0rem !important;
            width: 100% !important; } }
        @media (max-width: 1500px) {
          nav #mainMenu .nav__item-submenu .subList_menu {
            padding: 0px 0px 0px 6%; } }
        nav #mainMenu .nav__item-submenu .subList_menu li {
          width: 50%;
          float: left;
          list-style: none;
          margin: 0rem !important; }
          @media (max-width: 991px) {
            nav #mainMenu .nav__item-submenu .subList_menu li {
              width: 100%;
              float: none;
              display: inline-block; } }
          nav #mainMenu .nav__item-submenu .subList_menu li a {
            color: #29408e;
            display: flex;
            margin: 10px 0px;
            position: relative;
            text-align: left;
            background-color: none;
            font-size: 1.8rem;
            /*&:after
                            content: ""
                            position: absolute
                            width: calc(100% - 10px)
                            height: calc(100% - 10px)
                            border: 1px solid white
                            left: 50%
                            top: 50%
                            transform: translate(-50%,-50%)
                            transition: all 300ms ease
                            @media (max-width: 991px)
                                content: none*/ }
            @media (max-width: 1200px) {
              nav #mainMenu .nav__item-submenu .subList_menu li a {
                font-size: 1.4rem; } }
            @media (max-width: 991px) {
              nav #mainMenu .nav__item-submenu .subList_menu li a {
                width: 100%;
                height: auto;
                justify-content: flex-start;
                margin: 10px 0px;
                background: none;
                color: #29408e;
                font-size: 1.8vh; } }
            nav #mainMenu .nav__item-submenu .subList_menu li a:active {
              text-decoration: underline; }
            nav #mainMenu .nav__item-submenu .subList_menu li a:hover {
              text-decoration: underline; }
              nav #mainMenu .nav__item-submenu .subList_menu li a:hover:after {
                width: calc(100% - 16px);
                height: calc(100% - 16px); }
            nav #mainMenu .nav__item-submenu .subList_menu li a + .menuSmallpunkte {
              color: #29408e;
              float: left; }
  nav #burger {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px; }
    @media (min-width: 992px) {
      nav #burger {
        display: none; } }
    nav #burger input {
      visibility: hidden; }
    nav #burger input + label {
      position: absolute;
      top: 32px;
      right: 40px;
      height: 20px;
      width: 30px;
      z-index: 5; }
      @media (max-width: 480px) {
        nav #burger input + label {
          top: 22px; } }
      nav #burger input + label span {
        position: absolute;
        width: 100%;
        height: 3px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        display: block;
        background: #004189;
        transition: .5s; }
        nav #burger input + label span:first-child {
          top: 3px; }
        nav #burger input + label span:last-child {
          top: 16px; }
      nav #burger input + label:hover {
        cursor: pointer; }
    nav #burger input:checked + label span {
      opacity: 0;
      top: 50%; }
      nav #burger input:checked + label span:first-child {
        opacity: 1;
        transform: rotate(405deg); }
      nav #burger input:checked + label span:last-child {
        opacity: 1;
        transform: rotate(-405deg); }

.container {
  max-width: 1640px;
  margin: 0 auto; }

.container-brillen {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 800px;
  max-height: 800px;
  border: 1px solid #f0f0f0;
  overflow: hidden;
  z-index: 0; }

.bg200 {
  padding-bottom: 300px !important; }
  @media (max-width: 768px) {
    .bg200 {
      padding-bottom: 200px !important; } }

.bg520 {
  padding-bottom: 580px !important; }
  @media (max-width: 768px) {
    .bg520 {
      padding-bottom: 350px !important; } }
  @media (max-width: 568px) {
    .bg520 {
      padding-bottom: 200px !important; } }

.bg520_polari {
  padding-bottom: 580px !important; }
  @media (max-width: 768px) {
    .bg520_polari {
      padding-bottom: 350px !important; } }
  @media (max-width: 568px) {
    .bg520_polari {
      padding-bottom: 350px !important; } }

.bg700 {
  padding-bottom: 750px !important; }
  @media (max-width: 1150px) {
    .bg700 {
      padding-bottom: 750px !important; } }
  @media (max-width: 1024px) {
    .bg700 {
      padding-bottom: 550px !important; } }
  @media (max-width: 768px) {
    .bg700 {
      padding-bottom: 350px !important; } }
  @media (max-width: 568px) {
    .bg700 {
      padding-bottom: 200px !important; } }

.row {
  padding: 30px;
  display: block; }

.soft-row {
  display: flex;
  flex-wrap: wrap; }

.col-1 {
  flex: 1 0 100% !important;
  padding: 60px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  text-align: center;
  max-width: 100% !important; }
  .col-1 p {
    max-width: 50% !important;
    font-size: 1.5rem;
    line-height: 2.5rem; }
    @media (max-width: 767px) {
      .col-1 p {
        max-width: 90% !important; } }

.col-1_expand {
  flex: 1 0 100% !important;
  padding: 60px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  text-align: center;
  max-width: 100% !important; }
  .col-1_expand p {
    max-width: 80% !important;
    font-size: 1.5rem;
    line-height: 2.5rem; }
    @media (max-width: 767px) {
      .col-1_expand p {
        max-width: 90% !important; } }
  .col-1_expand h3 {
    max-width: 80% !important; }
    @media (max-width: 767px) {
      .col-1_expand h3 {
        max-width: 90% !important; } }

.col-2 {
  flex: 2 0 0; }

.col-3 {
  flex: 3 0 0; }

.col-4 {
  flex: 4 0 0; }

.col-5 {
  flex: 5 0 0; }

.col-6 {
  flex: 6 0 0; }

hr {
  margin: 20px 0; }

.headline {
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.3em;
  display: block;
  margin-bottom: 30px;
  padding: 5px 15px 5px 22px;
  color: #29408e;
  position: relative;
  border: 1px solid #29408e; }

.negative {
  color: white;
  border-color: white; }

.gridContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: white; }
  .gridContainer .sectionBTNS p {
    padding: 3em 0em !important; }
  .gridContainer .gridItem {
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    background: #fff;
    flex: 1 0 50%;
    min-width: 280px; }
    @media (max-width: 1024px) {
      .gridContainer .gridItem {
        flex: 1 0 100%;
        padding: 60px 40px; } }
    @media (min-width: 992px) {
      .gridContainer .gridItem {
        min-height: 400px; } }
    @media (max-width: 767px) {
      .gridContainer .gridItem {
        padding: 30px 20px; } }
    .gridContainer .gridItem h3 {
      text-align: center;
      max-width: 80%; }
      @media (max-width: 767px) {
        .gridContainer .gridItem h3 {
          text-align: center;
          max-width: 100%; } }
    .gridContainer .gridItem p {
      font-size: 1.5rem;
      line-height: 2.5rem;
      text-align: center;
      max-width: 80%; }
      @media (max-width: 767px) {
        .gridContainer .gridItem p {
          max-width: 90%; } }
    .gridContainer .gridItem ul {
      max-width: 80%;
      margin-left: 2.5rem; }
    .gridContainer .gridItem img {
      max-width: 100%; }
    .gridContainer .gridItem.video {
      background: #cdcdcd;
      position: relative;
      overflow: hidden;
      min-height: 200px;
      height: auto;
      width: 100%;
      padding-bottom: 500px;
      background-position: center; }
      @media (max-width: 768px) {
        .gridContainer .gridItem.video {
          padding-bottom: 350px; } }
      @media (max-width: 568px) {
        .gridContainer .gridItem.video {
          padding-bottom: 200px; } }
      .gridContainer .gridItem.video video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        max-height: 110%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
      .gridContainer .gridItem.video img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover; }
        .gridContainer .gridItem.video img.volmer {
          height: 100%;
          top: 60%;
          width: auto; }
    .gridContainer .gridItem.contentLeft {
      display: flex;
      align-items: end;
      padding: 0px 40px 20px 40px !important; }
      @media (min-width: 1024px) {
        .gridContainer .gridItem.contentLeft {
          padding: 60px 0px 60px 0px !important; } }
    .gridContainer .gridItem.contentLeft_nopadding {
      display: flex;
      align-items: end;
      padding: 0px !important; }
      @media (min-width: 1024px) {
        .gridContainer .gridItem.contentLeft_nopadding {
          padding: 0px !important; } }
    .gridContainer .gridItem.contentcenter {
      justify-content: flex-center;
      padding: 0px 40px 20px 40px !important; }
      @media (min-width: 1024px) {
        .gridContainer .gridItem.contentcenter {
          padding: 60px 40px 60px 0px !important; } }
    .gridContainer .gridItem.contentTop_nopadding {
      justify-content: flex-center;
      padding: 0px 40px 20px 40px !important; }
      @media (min-width: 1024px) {
        .gridContainer .gridItem.contentTop_nopadding {
          padding: 0px 40px 60px 40px !important; } }
    .gridContainer .gridItem.contentTop {
      justify-content: flex-center;
      padding: 0px 40px 20px 40px !important; }
      @media (min-width: 1024px) {
        .gridContainer .gridItem.contentTop {
          padding: 60px 40px 60px 0px !important; } }
      .gridContainer .gridItem.contentTop p {
        margin: 30px 0; }
    .gridContainer .gridItem#hoeren {
      background: #29408e; }
      .gridContainer .gridItem#hoeren p {
        color: white; }
    .gridContainer .gridItem#volmer {
      background: url("../img/background-float.jpg") 0% center no-repeat;
      animation: leftToRight 36s forwards infinite ease-in-out;
      background-size: cover !important; }
    .gridContainer .gridItem#standorte {
      padding: 60px; }
      .gridContainer .gridItem#standorte p {
        max-width: none;
        margin: 20px 0 30px; }
        .gridContainer .gridItem#standorte p b {
          font-size: inherit; }
      .gridContainer .gridItem#standorte button {
        all: unset;
        width: 50px;
        height: 50px;
        background: #29408e;
        color: white;
        display: inline-block; }
    .gridContainer .gridItem#karte {
      justify-content: flex-end;
      padding-bottom: 60px; }
    .gridContainer .gridItem#team {
      flex: 2 0 100%;
      padding: 0; }
    .gridContainer .gridItem#optiker {
      background: #fff;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      padding: 60px; }
      .gridContainer .gridItem#optiker p {
        margin: 1em 0em 3rem !important; }
        @media (max-width: 1024px) {
          .gridContainer .gridItem#optiker p {
            margin: 0rem auto 3rem !important;
            text-align: center !important; } }
      .gridContainer .gridItem#optiker h2 {
        margin: 0 0 10px; }
        @media (max-width: 1024px) {
          .gridContainer .gridItem#optiker h2 {
            margin: 1rem auto 3rem !important; } }
      .gridContainer .gridItem#optiker .inputWrapper {
        margin-bottom: 15px;
        position: relative; }
        .gridContainer .gridItem#optiker .inputWrapper:after {
          content: "OK";
          background: #29408e;
          color: white;
          display: inline-block;
          width: 50px;
          height: 100%;
          margin: 0;
          position: absolute;
          right: -55px;
          line-height: 36px;
          text-align: center;
          font-size: 1.2rem;
          letter-spacing: 0;
          top: 0; }
        .gridContainer .gridItem#optiker .inputWrapper:last-of-type:after {
          content: "\25BC";
          font-size: 0.825rem;
          text-align: center;
          margin: 0 auto;
          line-height: 35px;
          letter-spacing: 0; }
      .gridContainer .gridItem#optiker input, .gridContainer .gridItem#optiker select {
        all: unset;
        appearance: none;
        width: 200px;
        font-size: 1.3rem;
        padding: 5px 10px;
        border: 2px solid #29408e;
        color: #666;
        position: relative;
        display: inline-block;
        background: #fff; }
      .gridContainer .gridItem#optiker video {
        min-width: 100%;
        min-height: 100%;
        max-height: none;
        width: 100%; }
      .gridContainer .gridItem#optiker h1 {
        color: white;
        text-transform: uppercase;
        font-size: 5rem;
        letter-spacing: 0.8rem; }
      .gridContainer .gridItem#optiker p {
        margin: 0 0 20px;
        text-align: left; }
    .gridContainer .gridItem#newsletter {
      justify-content: flex-start;
      align-items: flex-start;
      background: url(../img/portraitofveautifulcheerfulyoungbusinesswomanworkingonlaptopandlaughinginhomeoffice.jpg) left bottom;
      background-size: cover; }
      .gridContainer .gridItem#newsletter h2 {
        text-align: left;
        font-size: 4rem;
        line-height: 1.1;
        letter-spacing: 0.01rem; }
      .gridContainer .gridItem#newsletter p {
        text-align: right; }
    .gridContainer .gridItem#brillenSlider {
      justify-content: flex-end;
      align-items: flex-end; }
      @media (max-width: 767px) {
        .gridContainer .gridItem#brillenSlider {
          padding: 30px 40px 60px; } }
      .gridContainer .gridItem#brillenSlider h2 {
        text-align: center;
        font-size: 4rem;
        line-height: 1.1;
        letter-spacing: 0.01rem;
        text-align: right;
        margin-bottom: 20px; }
      .gridContainer .gridItem#brillenSlider h3 {
        margin: 0 0 15px;
        color: #29408e;
        font-size: 1.4em; }
      .gridContainer .gridItem#brillenSlider p {
        text-align: right;
        max-width: none;
        margin: 0; }
      .gridContainer .gridItem#brillenSlider .circles {
        margin-top: 20px;
        width: 100%;
        text-align: right; }
        .gridContainer .gridItem#brillenSlider .circles .circle {
          width: 15px;
          height: 15px;
          background: #29408e;
          display: inline-block;
          border-radius: 100%;
          margin: 0 5px; }
          .gridContainer .gridItem#brillenSlider .circles .circle.active {
            border: 2px solid #29408e;
            background: #fff; }
      .gridContainer .gridItem#brillenSlider img {
        width: 350px;
        align-self: center; }
  .gridContainer .overlayDiv div {
    position: absolute;
    width: 100%;
    background: #29408e;
    padding: 1.5rem 0rem;
    bottom: 0px;
    text-align: left; }
    .gridContainer .overlayDiv div h4 {
      width: 83%;
      margin: 0 auto;
      color: #ffffff; }
      @media (max-width: 1024px) {
        .gridContainer .overlayDiv div h4 {
          text-align: center; } }
  .gridContainer .overlayDiv2 div {
    position: absolute;
    width: 100%;
    background: #333333;
    padding: 1.5rem 0rem;
    bottom: 0px;
    text-align: left; }
    .gridContainer .overlayDiv2 div h4 {
      width: 83%;
      margin: 0 auto;
      color: #ffffff; }
      @media (max-width: 1024px) {
        .gridContainer .overlayDiv2 div h4 {
          text-align: center; } }

@keyframes leftToRight {
  0%, 100% {
    background-position: 0% top;
    background-size: cover; }
  50% {
    background-position: 100% top; } }

.col-45_nopadding {
  padding: 60px 40px 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  background: #fff;
  flex: 1 0 50%;
  min-width: 280px;
  flex: 2 0 45% !important;
  text-align: left !important;
  align-items: flex-start !important;
  background-color: #ffffff !important;
  max-width: 100% !important; }
  @media (max-width: 1024px) {
    .col-45_nopadding {
      flex: 1 0 100% !important;
      align-items: center !important; } }

.col-45 {
  padding: 60px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  background: #fff;
  flex: 1 0 50%;
  min-width: 280px;
  flex: 2 0 45% !important;
  text-align: left !important;
  align-items: flex-start !important;
  background-color: #ffffff !important;
  max-width: 100% !important; }
  @media (max-width: 1024px) {
    .col-45 {
      padding: 30px 40px;
      flex: 1 0 100% !important;
      align-items: center !important; } }
  .col-45 p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0 !important;
    text-align: left !important;
    max-width: 100% !important; }
    @media (max-width: 768px) {
      .col-45 p {
        text-align: center !important; } }
  @media (max-width: 768px) {
    .col-45 h3 {
      text-align: center !important; } }

.col-55 {
  flex: 2 0 55% !important; }
  @media (max-width: 768px) {
    .col-55 {
      flex: 1 0 100% !important;
      padding: 0rem !important; } }
  .col-55 img {
    width: 100%; }

.sizeSlider * {
  color: #29408e; }

.sizeSlider img:nth-child(2) {
  margin-bottom: 2rem !important; }

.sizeSlider div {
  max-width: 80%; }
  @media (max-width: 480px) {
    .sizeSlider div {
      max-width: 100%; } }
  .sizeSlider div h5 {
    max-width: 100% !important;
    text-align: left !important;
    padding-left: 12px !important; }

.sizeSlider + div {
  padding-left: 0rem !important; }
  @media (max-width: 767px) {
    .sizeSlider + div {
      padding-left: 40px !important; } }

@media (max-width: 1023px) {
  .sizeSlider2 {
    flex: 1 0 100% !important; } }

.sizeSlider2 * {
  color: #29408e; }

.sizeSlider2 div {
  width: 100%; }
  .sizeSlider2 div div {
    border-bottom: 2px solid black;
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .sizeSlider2 div div {
        padding: 4rem 0rem 4rem 0rem;
        flex-direction: column; }
        .sizeSlider2 div div img {
          margin: 2rem auto 0 !important; }
        .sizeSlider2 div div:first-child {
          margin-top: 0rem !important; } }
  .sizeSlider2 div div:first-child img {
    margin-left: 11rem; }
  .sizeSlider2 div h5 {
    float: left; }
  .sizeSlider2 div img {
    margin: 3rem 0rem 3rem 10rem; }

.expandSection_special {
  align-items: start;
  background-color: #cae0f7;
  padding-bottom: 0rem !important; }
  .expandSection_special * {
    text-align: left; }
  .expandSection_special img {
    display: table;
    margin: 0 auto; }
  .expandSection_special h1 {
    color: #015793; }
  .expandSection_special h3 {
    color: #015793;
    font-weight: lighter; }
  .expandSection_special p {
    color: #69696b; }
  .expandSection_special a {
    color: white;
    background: #015793;
    padding: 1rem 1.2rem;
    margin-bottom: 40px; }

footer {
  background: #29408e;
  display: flex;
  padding: 15px 40px;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    footer {
      padding: 25px 40px;
      flex: 1 0 100%;
      flex-direction: column; } }
  footer p, footer a {
    color: white;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin: 0 10px; }
    footer p:hover, footer a:hover {
      color: rgba(255, 255, 255, 0.5); }
  @media (max-width: 767px) {
    footer div {
      text-align: center; } }
  @media (max-width: 767px) {
    footer div:first-child {
      margin-bottom: 1rem; } }

#kontaktlinsen_test {
  min-height: 940px !important; }
  @media (min-width: 800px) and (max-width: 1389px) {
    #kontaktlinsen_test {
      min-height: 940px !important; } }
  @media (min-width: 1390px) {
    #kontaktlinsen_test {
      min-height: 650px !important; } }

.blockTool {
  flex-direction: column;
  height: auto;
  background: #fff;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  min-width: 280px; }
  .blockTool div {
    height: 50%;
    padding: 40px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    text-align: center; }

#marken {
  padding: 3% 0%;
  width: 90%; }
  #marken .owl-nav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px !important;
    width: 100%;
    height: 40px; }
    #marken .owl-nav button {
      position: absolute; }
      #marken .owl-nav button:hover {
        background: transparent !important; }
      #marken .owl-nav button i {
        font-size: 3rem; }
    #marken .owl-nav .owl-prev {
      left: -60px;
      position: absolute;
      width: 40px;
      height: 40px;
      border: 1px solid white;
      border-radius: 50%; }
      #marken .owl-nav .owl-prev i {
        position: relative;
        left: -3px; }
    #marken .owl-nav .owl-next {
      right: -60px;
      position: absolute;
      width: 40px;
      height: 40px;
      border: 1px solid white;
      border-radius: 50%; }
      #marken .owl-nav .owl-next i {
        position: relative;
        right: -3px; }
  #marken .item img {
    width: inherit !important;
    margin: auto !important; }
  #marken .owl-stage {
    display: flex;
    align-items: center; }

.markenBTN {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: #b93121;
  font-size: 2rem !important;
  padding: 20px 20px;
  border-radius: 20px;
  display: table;
  letter-spacing: 1px;
  z-index: 10; }

.filialenMap {
  width: 100%;
  border: none !important;
    border-min-height: 400px; }
  @media (min-width: 1000px) {
    .filialenMap {
      min-height: 700px; } }

.mapContainer iframe {
  pointer-events: none; }

/*CONTACT SECTION*/
.some-page-wrapper {
  background-color: red;
  flex: 1 0 100%; }
  @media (min-width: 1000px) {
    .some-page-wrapper {
      flex: 1 0 50%; } }

.border_right {
  border-right: 7px solid white;
  box-sizing: border-box; }
  @media (max-width: 1000px) {
    .border_right {
      border: none; } }

.border_left {
  border-left: 7px solid white;
  box-sizing: border-box; }
  @media (max-width: 1000px) {
    .border_left {
      border: none; } }

.border_bottom {
  border-bottom: 7px solid white;
  box-sizing: border-box; }

.border_top {
  border-top: 7px solid white;
  box-sizing: border-box; }

@media (max-width: 1000px) {
  .borderB_noneMobile {
    border-bottom: none !important; } }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: auto;
  padding: 60px 40px; }
  @media (max-width: 767px) {
    .column {
      flex: 1 0 100%;
      padding: 30px 20px; } }
  @media (max-width: 1023px) {
    .column div h4 {
      text-align: center; } }
  @media (max-width: 1023px) {
    .column div p {
      text-align: center; } }
  .column div h4 + p {
    line-height: 25px; }
    @media (max-width: 1023px) {
      .column div h4 + p {
        text-align: center; } }

.datenschutz h4 {
  margin-bottom: 10px; }

.datenschutz .color_inherit {
  color: inherit; }

.datenschutz .col-1 {
  text-align: left;
  align-items: flex-start; }
  .datenschutz .col-1 p {
    max-width: 100% !important; }

.cc-window {
  opacity: 1;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

.cc-animate.cc-revoke {
  transition: transform 1s ease; }

.cc-grower {
  max-height: 0; }

.cc-revoke, .cc-window {
  z-index: 9999; }

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em; }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%; }

.cc-revoke {
  padding: .5em; }

.cc-link {
  opacity: .8;
  padding: .2em; }

.cc-link:hover {
  opacity: 1; }

.cc-btn {
  padding: .4em .8em;
  font-size: .9em;
  border-width: 2px; }

.cc-close {
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;
  line-height: .75; }

.cc-close:focus, .cc-close:hover {
  opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  display: none;
  position: absolute;
  bottom: 1em;
  right: 1em;
  border-radius: .5em;
  padding: 0.4em 1em;
  background: #9c0f3a;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.825rem;
  cursor: pointer; }

.cc-revoke.cc-left {
  left: 3em; }

.cc-revoke.cc-right {
  right: 3em; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em; }

.cc-floating .cc-compliance > .cc-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  -webkit-box-flex: 0; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

.cc-revoke {
  position: fixed;
  right: 1em;
  left: auto; }

.cc-window {
  color: white;
  background: #29408e;
  position: fixed;
  width: 50em !important;
  max-width: calc(100% - 2em);
  right: 2% !important;
  bottom: 2% !important;
  padding: 2em !important;
  padding-bottom: 2.5em !important;
  padding-top: 2.5em !important;
  left: auto !important;
  top: auto;
  border-radius: 4px;
  z-index: 50; }
  .cc-window .cookie--hinweis a.cc-link {
    color: white !important;
    font-size: 1.2rem !important;
    padding: 0 !important;
    text-decoration: underline;
    display: inline; }
  .cc-window .cookie--hinweis .cc-message {
    font-size: 1.2rem !important;
    color: white;
    margin: 0 !important;
    margin-right: 0 !important;
    display: inline; }
  .cc-window .cookie--hinweis .cc-compliance.cc-highlight {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center; }
    .cc-window .cookie--hinweis .cc-compliance.cc-highlight .cc-btn {
      display: block;
      margin: 0 1em 0.6em;
      font-size: 1.2rem;
      cursor: pointer;
      background: white;
      text-align: center;
      border-radius: 4px;
      padding: 0.3em 1em;
      color: #29408e; }
      .cc-window .cookie--hinweis .cc-compliance.cc-highlight .cc-btn.cc-allow {
        background: white;
        border: none;
        border-radius: 4px;
        padding: 0.3em 1em; }

.aktionHome {
  background-position: center !important;
  min-height: 450px !important; }
  @media (min-width: 480px) {
    .aktionHome {
      min-height: 550px !important; } }
  @media (min-width: 600px) {
    .aktionHome {
      min-height: 650px !important; } }
  @media (min-width: 720px) {
    .aktionHome {
      min-height: 790px !important; } }
  @media (min-width: 840px) and (max-width: 1024px) {
    .aktionHome {
      min-height: 950px !important; } }

@media (max-width: 1024px) {
  #newsletter {
    min-height: 600px; } }

@media (max-width: 767px) {
  #newsletter {
    background-position: 35% !important;
    min-height: 400px; } }

@media (max-width: 1024px) {
  #newsletter h2, #newsletter a {
    text-align: left !important; } }

@media (max-width: 1024px) {
  #newsletter h2 {
    margin: 0px !important; } }

@media (max-width: 480px) {
  #newsletter h2 {
    font-size: 30px !important; } }

@media (max-width: 1024px) {
  #newsletter a {
    margin: 20px 0px !important; } }

@media (max-width: 480px) {
  #newsletter a {
    font-size: 10px !important; } }

.btn-bewerten {
  font-size: 16px;
  color: #29408e !important;
  background: white;
  margin-top: 25px;
  display: table;
  padding: 10px; }
  @media (max-width: 1023px) {
    .btn-bewerten {
      margin: 25px auto 0px !important; } }
  .btn-bewerten::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url("/assets/img/Google-Reviews-icon_blue.png");
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: relative;
    display: inline-block;
    margin-right: 5px;
    top: 2px; }

.btn-bewerten-single {
  font-size: 16px;
  color: white !important;
  background: #29408e;
  margin-top: 25px;
  display: table;
  padding: 10px;
  margin: 25px auto !important; }
  @media (max-width: 1023px) {
    .btn-bewerten-single {
      margin: 25px auto 25px !important; } }
  .btn-bewerten-single::before {
    content: "";
    width: 16px;
    height: 16px;
    background: url("/assets/img/Google-Reviews-icon.png");
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: relative;
    display: inline-block;
    margin-right: 5px;
    top: 2px; }

.reviewContainer {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  margin: 20px auto 0px; }
  .reviewContainer p {
    max-width: 100% !important;
    padding: 0px 15px; }
  .reviewContainer .google {
    color: white;
    background: #29408e;
    text-align: center;
    padding: 15px;
    font-size: 16px;
    font-weight: bold; }
  .reviewContainer img {
    max-width: 100%; }

@media (max-width: 1024px) {
  .actionhome {
    padding: 50px 0px 50px 0px !important;
    min-height: 66vw !important; } }

@media (max-width: 767px) {
  .actionhome {
    min-height: 73vw !important; } }

.h1seo {
  font-size: 3em !important;
  margin-bottom: 30px;
  text-align: center !important; }

.stoererBaterie {
  background: white;
  color: #29408e;
  width: 20vw;
  height: 20vw;
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 2vw;
  transform: rotate(-15deg);
  display: flex;
  font-size: 18px;
  padding: 20px;
  align-items: center;
  text-align: center;
  line-height: 23px; }
  @media (max-width: 1200px) {
    .stoererBaterie {
      max-width: 160px;
      max-height: 160px;
      font-size: 16px !important;
      line-height: 18px; } }
  @media (max-width: 1024px) {
    .stoererBaterie {
      left: 10vw; } }
  @media (max-width: 767px) {
    .stoererBaterie {
      max-width: 180px;
      max-height: 180px;
      width: 150px !important;
      height: 150px !important;
      font-size: 14px !important;
      top: 80px !important; } }
  @media (max-width: 480px) {
    .stoererBaterie {
      max-height: 120px !important;
      max-width: 120px !important;
      font-size: 11.1px !important;
      line-height: 14px !important;
      top: 90px !important; } }
  .stoererBaterie a {
    position: absolute;
    top: 0px;
    height: 0px;
    width: 100%;
    height: 100%; }

@media (max-width: 767px) {
  #hoeren .headline.negative {
    margin-bottom: 150px; } }

@media (max-width: 480px) {
  #hoeren .headline.negative {
    margin-bottom: 170px !important; } }

.actionhome {
  padding: 0px !important;
  background-image: none !important;
  background-size: cover !important;
  background-position: bottom !important;
  background-repeat: no-repeat !important;
  justify-content: end !important; }
  @media (max-width: 1024px) {
    .actionhome {
      background-image: none !important;
      order: 2; } }

.aktionJens_volmer_img {
  opacity: 1 !important;
  transform: none !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  position: relative !important; }
  @media (max-width: 1024px) {
    .aktionJens_volmer_img {
      display: block; } }

.aktionJens_volmer_img + p {
  position: absolute;
  left: 3vw;
  top: 2vw;
  width: 60%;
  color: #29408e !important;
  font-size: 2.5rem !important;
  line-height: 3.5rem !important;
  text-align: left !important; }
  @media (max-width: 1400px) {
    .aktionJens_volmer_img + p {
      width: 80%; } }
  @media (max-width: 1024px) {
    .aktionJens_volmer_img + p {
      top: 5vw;
      left: 5vw; } }
  @media (max-width: 768px) {
    .aktionJens_volmer_img + p {
      font-size: 1.8rem !important;
      line-height: 2.3rem !important;
      width: 100%;
      text-align: center !important; } }
  .aktionJens_volmer_img + p * {
    color: #29408e !important;
    font-size: 2.5rem !important;
    line-height: 3.5rem !important; }
    @media (max-width: 768px) {
      .aktionJens_volmer_img + p * {
        font-size: 1.8rem !important;
        line-height: 2.3rem !important; } }

.jensvolmer-action-Textsmall {
  position: absolute;
  color: black !important;
  top: 50%;
  transform: translateY(-50%);
  max-width: 40% !important;
  text-align: right !important;
  left: 3vw; }
  @media (max-width: 1300px) {
    .jensvolmer-action-Textsmall {
      font-size: 1.3rem !important;
      line-height: 1.5rem !important;
      transform: translateY(-30%) !important;
      top: 30%; } }
  @media (max-width: 1024px) {
    .jensvolmer-action-Textsmall {
      top: 50%;
      max-width: 200px !important;
      left: calc(30vw - 40px); } }
  @media (max-width: 767px) {
    .jensvolmer-action-Textsmall {
      left: calc(30vw - 100px); } }
  .jensvolmer-action-Textsmall b {
    font-size: 1.3rem !important; }

.flexDiv {
  display: flex;
  min-width: 100%;
  flex-wrap: wrap; }

.text-start-align strong {
  font-size: 1.6rem !important; }

#TeamSlider img {
  max-width: 150px;
  margin: auto; }

#TeamSlider p {
  margin: 20px auto; }
  #TeamSlider p i {
    font-size: 16px; }

.lilaText {
  color: #29408e !important; }

#toggle {
  width: 100%; }
  #toggle dt {
    padding: 10px 20px;
    cursor: pointer;
    background: #29408e;
    color: #ffffff;
    border-radius: 2px;
    position: relative; }
    #toggle dt:after {
      content: "\25BE";
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: inherit;
      transform: rotate(0deg);
      transition: all .3s ease; }
    #toggle dt.active:after {
      transform: rotate(-180deg); }
  #toggle dd {
    padding: 0 20px;
    background: #e6e6e6;
    height: 0;
    overflow: hidden;
    margin-bottom: 10px; }
    #toggle dd.active {
      height: auto;
      padding: 20px; }

.btn {
  padding: 10px 25px;
  background: #29408e;
  color: #ffffff;
  display: inline-block;
  border-radius: 2px;
  margin: 0 10px 10px 0;
  transition: all 300ms ease; }
  .btn:hover {
    background: #3452b6;
    color: #ffffff; }

.btn-outline {
  padding: 2px 15px 2px 25px;
  border: 2px solid #29408e;
  transition: all 300ms ease;
  color: #29408e;
  display: inline-block;
  text-transform: uppercase;
  font-size: 2.6rem;
  letter-spacing: 0.7rem;
  font-weight: 200;
  text-align: center;
  margin: 20px 0; }
  .btn-outline:hover {
    background: #3452b6;
    color: #ffffff; }

.btn-outline-small {
  padding: 4px 14px 4px 21px;
  transition: all 300ms ease;
  border: 2px solid #29408e;
  color: #29408e;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.2rem; }
  @media (max-width: 1024px) {
    .btn-outline-small {
      margin: 0 auto !important; } }
  .btn-outline-small:hover {
    background: #3452b6;
    color: #ffffff; }

.btn-outline-neg {
  margin: 20px 0;
  padding: 4px 14px 4px 21px;
  border: 2px solid white;
  color: white;
  display: inline-block;
  text-transform: uppercase;
  transition: all 300ms ease;
  font-size: 2.6rem;
  letter-spacing: 0.7rem;
  font-weight: 200; }
  .btn-outline-neg:hover {
    background: white;
    color: #29408e; }

.section__links {
  display: flex;
  width: 100%; }
  @media (max-width: 767px) {
    .section__links {
      flex-direction: column; } }
  .section__links .section__link {
    display: block;
    position: relative;
    background: #29408e;
    padding: 20px 10px;
    color: white;
    width: 100%;
    margin: 1rem auto;
    width: 60%;
    font-size: 1.5em;
    text-align: center;
    transition: all 300ms ease; }
    @media (min-width: 768px) {
      .section__links .section__link {
        margin: 0rem 1rem !important; } }
    @media (min-width: 992px) {
      .section__links .section__link {
        font-size: 1em; } }
    @media (min-width: 1200px) {
      .section__links .section__link {
        font-size: 1.3em; } }
    .section__links .section__link:hover {
      background: #3452b6;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15); }
  .section__links.negative .section__link {
    transition: all 300ms ease;
    color: #29408e !important;
    background: white; }
    .section__links.negative .section__link:hover {
      background: #eff1fa; }

.actionhome + div .section__link {
  display: block;
  position: relative;
  background: #29408e;
  padding: 20px 10px;
  color: white;
  width: 100%;
  margin: 1rem auto;
  width: 50%;
  font-size: 1.5em;
  text-align: center;
  transition: all 300ms ease; }
  @media (min-width: 768px) {
    .actionhome + div .section__link {
      margin: 0rem 1rem !important; } }
  @media (min-width: 992px) {
    .actionhome + div .section__link {
      font-size: 1em; } }
  @media (min-width: 1200px) {
    .actionhome + div .section__link {
      font-size: 1.3em; } }
  .actionhome + div .section__link:hover {
    background: #3452b6;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15); }

form {
  max-width: 600px;
  background: #efefef;
  padding: 20px; }
  form input[type="text"],
  form input[type="email"],
  form input[type="tel"],
  form input[type="range"],
  form textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 5px 10px;
    margin-top: 5px;
    outline: none;
    border: none; }
  form input {
    padding: 5px 10px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    margin-top: 5px; }
  form label {
    display: inline-block; }
  form .labelRadio {
    margin-right: 20px; }
  form input[type="checkbox"],
  form input[type="radio"] {
    margin-right: 5px; }

.stickies {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999;
  transition: all 500ms ease; }
  .stickies .sticky {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #29408e;
    color: white;
    padding: 10px;
    font-size: 1.1em;
    margin: 10px 0;
    width: 80px; }
    .stickies .sticky img {
      margin-bottom: 5px; }
  .stickies.scrolled {
    right: -80px; }

#stoerer-aktion {
  position: absolute;
  max-width: 300px;
  width: 100%;
  left: 6%;
  top: 30%; }
  @media (max-width: 1199px) {
    #stoerer-aktion {
      max-width: 220px; } }
  @media (max-width: 767px) {
    #stoerer-aktion {
      max-width: 180px; } }
  @media (max-width: 620px) {
    #stoerer-aktion {
      max-width: 30%;
      top: 25%; } }

@media (max-width: 1024px) {
  #aktion-page .text-centered-mobil {
    text-align: center !important; } }

@media (max-width: 1024px) {
  #aktion-page .button-centered-mobile {
    margin: auto !important; } }

#aktion-page #TeamSlider {
  max-width: 60%; }
  #aktion-page #TeamSlider * {
    text-align: center; }

#aktion-page h1, #aktion-page h2, #aktion-page h3, #aktion-page h4, #aktion-page h5 {
  font-size: 3em !important;
  line-height: 3.5rem !important;
  margin-bottom: 30px !important; }

#aktion-page .headline_lila {
  color: #29408e !important; }

.col-70-width-desktop {
  padding-right: 300px; }

.row-direction {
  flex-direction: row !important;
  align-items: center !important; }

.pb-pt-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.row_centered-70_porcent {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between; }
  .row_centered-70_porcent > div {
    width: 30%;
    text-align: center; }
    @media (max-width: 767px) {
      .row_centered-70_porcent > div {
        width: 100%; } }

.row_centered-70_porcent.icons p {
  max-width: 100% !important; }

.row_centered-70_porcent.icons img {
  max-width: 150px; }

.row_centered-90_porcent {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between; }
  @media (max-width: 999px) {
    .row_centered-90_porcent {
      justify-content: center; } }
  .row_centered-90_porcent > div {
    width: 18%;
    text-align: center; }
    @media (max-width: 999px) {
      .row_centered-90_porcent > div {
        width: 30%;
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .row_centered-90_porcent > div {
        width: 100%; } }

.row_centered-90_porcent.icons p {
  max-width: 100% !important;
  margin-top: 10px; }

.row_centered-90_porcent.icons img {
  max-width: 150px; }
  @media (max-width: 1199px) {
    .row_centered-90_porcent.icons img {
      max-width: 120px; } }

@media (max-width: 991px) {
  .mobile_pb {
    padding-bottom: 60px !important; } }

.button_centered {
  margin: auto !important;
  max-width: 200px !important; }
  .button_centered a {
    width: 100% !important; }

.mb-5 {
  margin-bottom: 25px !important; }

.mt-5 {
  margin-top: 25px !important; }

.zitat-content {
  padding-left: 60px;
  padding-right: 60px; }
  @media (max-width: 767px) {
    .zitat-content {
      padding-left: 0px !important;
      padding-right: 0px !important; } }
  .zitat-content .zitat {
    position: relative; }
    .zitat-content .zitat:before {
      position: absolute;
      left: -40px;
      top: -5px;
      content: "";
      background: url("/assets/img/sonnenbrille-aktion/zitat-before@2x.png");
      background-size: contain;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat; }
      @media (max-width: 767px) {
        .zitat-content .zitat:before {
          left: 0px !important; } }
    .zitat-content .zitat:after {
      position: absolute;
      right: -10px;
      bottom: -10px;
      content: "";
      background: url("/assets/img/sonnenbrille-aktion/zitat-after@2x.png");
      background-size: contain;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat; }
      @media (max-width: 767px) {
        .zitat-content .zitat:after {
          right: 0px !important; } }

.square-bg-image {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  min-height: 700px !important; }
  @media (max-width: 767px) {
    .square-bg-image {
      min-height: 400px !important; } }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

@media (min-width: 1024px) {
  .order-lg-1 {
    order: 1; } }

@media (min-width: 1024px) {
  .order-lg-2 {
    order: 2; } }

#uc-full-optin-description {
  font-size: 14px !important; }

.uc-embedding-wrapper {
  width: 40vw;
  padding: 40px; }
  @media (max-width: 999px) {
    .uc-embedding-wrapper {
      width: 80vw !important; } }
  .uc-embedding-wrapper h3 {
    max-width: 100% !important;
    color: #29408e !important;
    margin-bottom: 15px !important; }
  .uc-embedding-wrapper .description-text {
    max-width: 100% !important; }
  .uc-embedding-wrapper .uc-embedding-buttons {
    margin-top: 20px; }
    .uc-embedding-wrapper .uc-embedding-buttons .uc-embedding-accept {
      background: #29408e !important;
      border: 2px solid #29408e !important; }
    .uc-embedding-wrapper .uc-embedding-buttons .uc-embedding-more-info {
      background: transparent !important;
      border: 2px solid #29408e !important;
      color: #29408e !important; }
