@font-face {
  font-family: 'VolmerSansWeb-Light';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/VolmerSansWeb-Light.eot');
  src: local(""), url('/assets/fonts/VolmerSansWeb-Light.eot?#iefix') format("embedded-opentype") url('/assets/fonts/VolmerSansWeb-Light.woff2') format("woff2"), url('/assets/fonts/VolmerSansWeb-Light.woff') format("woff"), url('/assets/fonts/VolmerSansWeb-Light.ttf') format("truetype"), url('/assets/fonts/VolmerSansWeb-Light.svg#Poppins') format("svg"); }

@font-face {
  font-family: 'VolmerSansWeb-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/VolmerSansWeb-Regular.eot');
  src: local(""), url('/assets/fonts/VolmerSansWeb-Regular.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/VolmerSansWeb-Regular.woff2') format("woff2"), url('/assets/fonts/VolmerSansWeb-Regular.woff') format("woff"), url('/assets/fonts/VolmerSansWeb-Regular.ttf') format("truetype"), url('/assets/fonts/VolmerSansWeb-Regular.svg#Poppins') format("svg"); }

@font-face {
  font-family: 'VolmerSansWeb-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/VolmerSansWeb-Bold.eot');
  src: local(""), url('/assets/fonts/VolmerSansWeb-Bold.eot?#iefix') format("embedded-opentype"), url('/assets/fonts/VolmerSansWeb-Bold.woff2') format("woff2"), url('/assets/fonts/VolmerSansWeb-Bold.woff') format("woff"), url('/assets/fonts/VolmerSansWeb-Bold.ttf') format("truetype"), url('/assets/fonts/VolmerSansWeb-Bold.svg#Poppins') format("svg"); }


* {
	font-family: 'VolmerSansWeb-Regular', sans-serif;
	font-size: calc(10px + 10%); }

body {

	color: $blue; }

p, a, li, b {
	font-size: 1.5rem; }
a {
	color: $blue;
	&:hover {
		color: $blue; } }
li {
	margin-bottom: 5px; }
h1 {
	font-size: 4em;
	margin-bottom: 30px; }
h3 {
	font-size: 2em;
	margin-bottom: 10px;
	@media (min-width: 768px) {
		font-size: 3em;
		margin-bottom: 30px; } }

h4 {
	font-size: 2.2em;
	margin-bottom: 30px; }
h5 {
	font-size: 1.8em;
	/*margin-bottom: 30px*/ }
strong, b, h1,h2,h3,h4,h5,h6 {
	font-family: 'VolmerSansWeb-Bold', sans-serif;
	font-weight: 700; }

h2 {
	font-size: 4em;
	margin-bottom: 20px !important;
	@media (max-width: 1024px) {
		margin: 0 auto 3rem !important;
		text-align: center !important; }
	& + h5 {
		margin: 0rem 0rem 1.5rem !important;
		@media (max-width: 1024px) {
			margin: 0rem auto 1.5rem !important;
			text-align: center !important; }
		& + p {
			@media (max-width: 1024px) {
				text-align: center !important; } } } }
h4, h5 {
	@media (max-width: 768px) {
		text-align: center !important; } }

