.nav__item-cta {
    position: relative;
    margin: 0 0 0 30px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: .05em;
    display: inline-block;
    top: 33px;
    @media (max-width: 991px) {
        display: none !important; }
    .tel_jens {
        padding: 5px 10px;
        background-color: #29408e;
        color: #fff !important; } }
.telBtn {
    @media (min-width: 992px) {
        display: none !important; } }
.tel_jens * {
    color: white !important; }
.overlay {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    background: #004189;
    z-index: 20;
    opacity: 0.6; }
nav {
    width: 100%;
    max-width: 1640px;
    z-index: 999999;
    position: relative;
    background: #ffffff;
    padding: 10px 40px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 0 6px rgba(black, 0);
    box-shadow: 2px 0 8px rgba(black, 0.15);
    align-items: center;
    #logo {
        width: 30%;
        z-index: 999999;
        max-width: 170px;
        position: relative;
        @media (max-width: 991px) {
            width: 80%; }
        img {
            max-width: 180px;
            @media (max-width: 480px) {
                max-width: 120px; } } }
    #mainMenu {
        width: 100%;
        right: 40px;
        text-align: right;
        position: absolute;
        height: 100%;
        @media (max-width: 991px) {
            top: 100%;
            left: 0;
            right: 0;
            height: auto;
            background: #ffffff;
            display: none;
            visibility: hidden;
            opacity: 0;
            padding: 1rem 0rem;
            overflow-y: scroll;
            max-height: 70vh; }
        .nav__item,.nav__item-cta {
            position: relative;
            margin: 0 0 0 30px;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            display: inline-block;
            top: 33px;
            @media (max-width: 767px) {
                    font-size: 2.3rem; }
            @media (min-width: 768px) and (max-width: 991px) {
                    font-size: 3rem; }
            @media (max-width: 991px) {
                    display: block;
                    text-align: left;
                    top: 0;
                    padding: 0 40px 0 40px;
                    margin: 1rem 0rem; }
            &:hover {
                text-decoration: underline; } }
        & .nav__item-submenu {
            display: none;
            width: 100%;
            left: 40px;
            top: 100%;
            background: #ffffff;
            padding: 0px;
            position: absolute;
            -webkit-box-shadow: 0 0 6px rgba(0,0,0,0);
            box-shadow: 0 0 6px rgba(0,0,0,0);
            -webkit-box-shadow: 2px 0 8px rgba(0,0,0,.15);
            box-shadow: 2px 0 8px rgba(0,0,0,.15);
            @media (max-width: 991px) {
                display: none;
                position: relative;
                left: 0px;
                top: 0;
                padding: 0px 40px;
                background: #ffffff;
                box-shadow: none !important; }
            .bgImageMenu {
                width: 50%;
                float: left;
                line-height: 0px;
                @media (max-width: 991px) {
                    display: none; } }
            .subList_menu {
                width: 50%;
                float: left;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: 0px 0px 0px 120px;
                @media (max-width: 991px) {
                    float: none;
                    padding: 0px !important;
                    top: 0px;
                    transform: none !important;
                    position: relative;
                    padding: 1rem 0rem 1.5rem 0rem !important;
                    width: 100% !important; }
                @media (max-width: 1500px) {
                    padding: 0px 0px 0px 6%; }
                li {
                    width: 50%;
                    float: left;
                    list-style: none;
                    margin: 0rem !important;
                    @media (max-width: 991px) {
                        width: 100%;
                        float: none;
                        display: inline-block; }
                    a {
                        color: $blue;
                        display: flex;
                        margin: 10px 0px;
                        position: relative;
                        text-align: left;
                        background-color: none;
                        font-size: 1.8rem;
                        @media (max-width: 1200px) {
                            font-size: 1.4rem; }
                        @media (max-width: 991px) {
                            width: 100%;
                            height: auto;
                            justify-content: flex-start;
                            margin: 10px 0px;
                            background: none;
                            color: $blue;
                            font-size: 1.8vh; }
                        &:active {
                            text-decoration: underline; }
                        /*&:after
                            content: ""
                            position: absolute
                            width: calc(100% - 10px)
                            height: calc(100% - 10px)
                            border: 1px solid white
                            left: 50%
                            top: 50%
                            transform: translate(-50%,-50%)
                            transition: all 300ms ease
                            @media (max-width: 991px)
                                content: none*/
                        &:hover {
                            text-decoration: underline;
                            &:after {
                                width: calc(100% - 16px);
                                height: calc(100% - 16px); } }
                        &+.menuSmallpunkte {
                            color: $blue;
                            float: left; } } } } } }

    #burger {
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        @media (min-width: 992px) {
            display: none; }
        & input {
            visibility: hidden; }
        & input + label {
            position: absolute;
            top: 32px;
            right: 40px;
            height: 20px;
            width: 30px;
            z-index: 5;
            @media (max-width: 480px) {
                top: 22px; }
            & span {
                position: absolute;
                width: 100%;
                height: 3px;
                top: 50%;
                margin-top: -1px;
                left: 0;
                display: block;
                background: #004189;
                transition: .5s;
                &:first-child {
                    top: 3px; }
                &:last-child {
                    top: 16px; } }
            &:hover {
                cursor: pointer; } }
        & input:checked + label {
             & span {
                opacity: 0;
                top: 50%;
                &:first-child {
                    opacity: 1;
                    transform: rotate(405deg); }
                &:last-child {
                    opacity: 1;
                    transform: rotate(-405deg); } } } } }



