.btn {
    padding: 10px 25px;
    background: $blue;
    color: color-contrast($blue);
    display: inline-block;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    transition: all 300ms ease;
    &:hover {
        background: lighten($blue, 10%);
        color: color-contrast($blue); } }
.btn-outline {
    padding: 2px 15px 2px 25px;
    border: 2px solid $blue;
    transition: all 300ms ease;
    color: $blue;
    display: inline-block;
    text-transform: uppercase;
    font-size: 2.6rem;
    letter-spacing: 0.7rem;
    font-weight: 200;
    text-align: center;
    margin: 20px 0;

    &:hover {
        background: lighten($blue, 10%);
        color: color-contrast($blue); } }
.btn-outline-small {
    padding: 4px 14px 4px 21px;
    transition: all 300ms ease;
    border: 2px solid $blue;
    color: $blue;
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    @media (max-width: 1024px) {
        margin: 0 auto !important; }

    &:hover {
        background: lighten($blue, 10%);
        color: color-contrast($blue); } }
.btn-outline-neg {
    margin: 20px 0;
    padding: 4px 14px 4px 21px;
    border: 2px solid white;
    color: white;
    display: inline-block;
    text-transform: uppercase;
    transition: all 300ms ease;
    font-size: 2.6rem;
    letter-spacing: 0.7rem;
    font-weight: 200;

    &:hover {
        background: white;
        color: $blue; } }
.section__links {
    display: flex;
    width: 100%;
    @media (max-width: 767px) {
        flex-direction: column; }
    .section__link {
        display: block;
        position: relative;
        background: $blue;
        padding: 20px 10px;
        color: white;
        width: 100%;
        margin: 1rem auto;
        width: 60%;
        font-size: 1.5em;
        text-align: center;
        transition: all 300ms ease;
        @media (min-width: 768px) {
            margin: 0rem 1rem !important; }
        @media (min-width: 992px) {
            font-size: 1em; }
        @media (min-width: 1200px) {
            font-size: 1.3em; }
        &:hover {
            background: lighten($blue, 10%);
            box-shadow: 2px 2px 8px rgba(black, 0.15); } }

    &.negative {
        .section__link {
            transition: all 300ms ease;
            color: $blue !important;
            background: white;
            &:hover {
                background: lighten($blue, 60%); } } } }
.actionhome + div {
    .section__link {
        display: block;
        position: relative;
        background: $blue;
        padding: 20px 10px;
        color: white;
        width: 100%;
        margin: 1rem auto;
        width: 50%;
        font-size: 1.5em;
        text-align: center;
        transition: all 300ms ease;
        @media (min-width: 768px) {
            margin: 0rem 1rem !important; }
        @media (min-width: 992px) {
            font-size: 1em; }
        @media (min-width: 1200px) {
            font-size: 1.3em; }
        &:hover {
            background: lighten($blue, 10%);
            box-shadow: 2px 2px 8px rgba(black, 0.15); } } }
