* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4;
    position: relative; }

body {
    background: #F5F5F5;
    color: #333; }


p {
    line-height: 21px;
    margin-bottom: 15px; }
.noPadding {
    padding: 0rem; }
.noPadding_ltr {
    padding: 0rem 0rem 0px 0rem !important; }
img {
    max-width: 100%; }
.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
.menuShowed {
    visibility: visible !important;
    opacity: 1 !important;
    display: block !important;
    transition: all .3s ease; }
.activePage {
    text-decoration: underline; }
.lilaSection {
    background-color: #29408e !important;
    & * {
        color: #ffffff !important;
 } }        //max-width: 70% !important
.bgImage {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
.bgImage_right {
    background-position: right !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
#carouselProduct {
    margin: 2rem 0rem 4rem;
    & .owl-item {
        & .item {
            & img {
                float: right;
                margin: 4rem 0rem 0rem;
                @media (max-width: 1024px) {
                    float: none;
                    margin: 4rem auto 0rem; } } } }
    & .owl-dots {
        position: absolute;
        top: 0px;
        right: 0px;
        @media (max-width: 1024px) {
            bottom: -35px !important;
            top: auto;
            left: 0px;
            right: 0px;
            margin: auto !important; }
        & .owl-dot {
            display: inline-block;
            & span {
                background: #29408e !important;
                border: 2px solid #29408e;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin: 0rem 1rem;
                &:last-child {
                    margin-right: 0rem !important; } }
            &.active {
                & span {
                    background-color: white !important;
                    border: 2px solid #29408e; } }
            &:focus {
                outline: none !important;
                & span {
                    outline: none !important; } } } } }
.seeOnline_tool {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    a {
        position: absolute;
        border: 1px solid #29408e;
        background: #29408e;
        z-index: 999999999;
        top: 30%;
        right: 0px;
        left: 0px;
        bottom: 0px;
        margin: auto;
        display: table;
        transform: translateY(-30%);
        padding: 20px 20px;
        font-size: 1.5em;
        color: white;
        transition: all 0.2s linear;
        box-sizing: border-box;
        @media (max-width: 767px) {
            padding: 12px 12px;
            font-size: 1.1em; }
        &:hover {
            transition: all 0.2s linear;
            border: 1px solid white;
            background: transparent; } } }

.seeOnline_tool_middle {
    width: 100%;
    height: calc(100% - 61px);
    left: 0px;
    top: 0px;
    position: absolute;
    a {
        position: absolute;
        border: 1px solid #29408e;
        background: #29408e;
        z-index: 999999999;
        top: 50%;
        right: 0px;
        left: 0px;
        bottom: 0px;
        margin: auto;
        display: table;
        transform: translateY(-50%);
        padding: 20px 20px;
        font-size: 1.5em;
        color: white;
        transition: all 0.2s linear;
        box-sizing: border-box;
        @media (max-width: 767px) {
            padding: 12px 12px;
            font-size: 1.1em; }
        &:hover {
            transition: all 0.2s linear;
            border: 1px solid white;
            background: transparent; } } }

#videoIframe_scription {
    height: 60vh; }

@media (max-width: 1024px) {
    #aktion_section {
        .text-centered-mobil {
            text-align: center !important; }
        .buttonCentered {
            margin: 0 auto !important; } } }
.aktion_wrap {
    display: flex !important;
    flex-wrap: wrap !important;
    @media (max-width: 1024px) {
        & > div:first-child {
            order: 2; } } }
.button {
    padding: 20px 10px;
    font-size: 1.3em; }

body .button_alone {
    max-width: 200px;
    text-align: center; }

body .button_alone a {
    margin: 0 !important;
    width: 100% !important; }

.text-start-align li, .text-start-align p {
    text-align: left !important;
    list-style: none !important; }

.text-start-align h1, .text-start-align h3 {
    text-align: left !important; }

.lilaButton {
    background: #29408e !important;
    color: #fff !important; }
#navigation {
    display: flex; }

.text-center {
    text-align: center; }
@media (max-width: 1024px) {
    #aktion_section {
        .text-centered-mobil {
            text-align: center !important; }
        .buttonCentered {
            margin: 0 auto !important; } } }

.text-start-align {
    justify-content: flex-start !important;
    p {
        max-width: 100% !important; }
    p,li {
        text-align: left !important;
        list-style: none !important; }
    strong {
        font-size: 1.6rem !important; }
    h1,h3 {
        text-align: left !important; }
    ul {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 15px !important;
        li {
            margin-bottom: 8px;
            list-style-type: disc !important; } } }


/*DIGITALER SEHSTRESS SEITE*/
.digitaler-sehstress,.myopie,.sehstress-bei-kindern {
    .gridContainer .gridItem p {
        max-width: 100% !important; }
    footer {
        display: flex !important; }
    .blauZitat {
        font-size: 3rem !important;
        font-weight: bold !important;
        line-height: 1.4 !important;
        color: #29408e !important;
        position: relative;
        &:before {
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            left: -16px;
            top: -10px;
            background: url("/assets/img/zitat_top.png");
            background-size: contain !important;
            background-repeat: no-repeat !important;
            background-position: center !important; }
        span {
            height: 40px;
            width: 40px;
            position: relative;
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                position: absolute;
                right: -20px;
                top: -10px;
                background: url("/assets/img/zitat_bottom.png");
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center !important; } } }
    .gridItem.text-left {
        align-items: center !important;
        justify-content: center !important;
        * {
            text-align: left !important; } }
    .main_container {
        max-width: 1640px !important;
        margin: 0 auto !important;
        .headerbild-widht-imgBG {
            padding-top: 15%;
            padding-bottom: 15%;
            min-height: 600px;
            max-height: 40vw !important;
            @media (min-width: 992px) and (max-width: 1590px) {
                min-height: 670px !important; }
            @media (max-width: 991px) {
                padding-top: 50px;
                padding-bottom: 50px;
                max-height: inherit !important;
                min-height: auto !important; } }

        .digitaler-sehstress-headerbild {
            @media (min-width: 992px) {
                background: url("/assets/img/digitaler-sehstress/digitaler-sehstress-headerbild.jpg");
                background-size: cover !important;
                background-position: right center !important;
                background-repeat: no-repeat !important; } }
        h1,h2,h3 {
            font-weight: bold;
            font-size: 3em !important;
            line-height: 1.4 !important; }

        .blueBox_floatingRight {
            background: #29408e !important;
            max-width: 300px;
            padding: 3rem;
            align-self: center;
            * {
                color: white; } } } }


.headerbild-widht-imgBG {
    padding-top: 15%;
    padding-bottom: 15%;
    @media (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 50px; } }

.myopie-headerbild {
    @media (min-width: 992px) {
        background: url("/assets/img/digitaler-sehstress/myopie-headerbild.jpg");
        background-size: cover !important;
        background-position: right center !important;
        background-repeat: no-repeat !important; } }

.sehstress-bei-kindern-headerbild {
    @media (min-width: 992px) {
        background: url("/assets/img/digitaler-sehstress/sehstress-bei-kindern-headerbild.jpg");
        background-size: cover !important;
        background-position: right center !important;
        background-repeat: no-repeat !important; } }
