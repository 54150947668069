#uc-full-optin-description {
    font-size: 14px !important; }
.uc-embedding-wrapper {
    width: 40vw;
    padding: 40px;
    @media (max-width: 999px) {
        width: 80vw !important; }
    h3 {
        max-width: 100% !important;
        color: $blue !important;
        margin-bottom: 15px !important; }
    .description-text {
        max-width: 100% !important; }
    .uc-embedding-buttons {
        margin-top: 20px;
        .uc-embedding-accept {
            background: $blue !important;
            border: 2px solid $blue !important; }
        .uc-embedding-more-info {
            background: transparent !important;
            border: 2px solid $blue !important;
            color: $blue !important; } } }
