.container {
    max-width: 1640px;
    margin: 0 auto; }
.container-brillen {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 800px;
    max-height: 800px;
    border: 1px solid $light-grey;
    overflow: hidden;
    z-index: 0; }
.bg200 {
    padding-bottom: 300px !important;
    @media (max-width: 768px) {
        padding-bottom: 200px !important; } }
.bg520 {
    padding-bottom: 580px !important;
    @media (max-width: 768px) {
        padding-bottom: 350px !important; }
    @media (max-width: 568px) {
        padding-bottom: 200px !important; } }
.bg520_polari {
    padding-bottom: 580px !important;
    @media (max-width: 768px) {
        padding-bottom: 350px !important; }
    @media (max-width: 568px) {
        padding-bottom: 350px !important; } }
.bg700 {
    padding-bottom: 750px !important;
    @media (max-width: 1150px) {
        padding-bottom: 750px !important; }
    @media (max-width: 1024px) {
        padding-bottom: 550px !important; }
    @media (max-width: 768px) {
        padding-bottom: 350px !important; }
    @media (max-width: 568px) {
        padding-bottom: 200px !important; } }
.row {
    padding: 30px;
    display: block; }
.soft-row {
    display: flex;
    flex-wrap: wrap; }
.col-1 {
    flex: 1 0 100% !important;
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    text-align: center;
    max-width: 100% !important;
    & p {
        max-width: 50% !important;
        font-size: 1.5rem;
        line-height: 2.5rem;
        @media (max-width: 767px) {
            max-width: 90% !important; } } }
.col-1_expand {
    flex: 1 0 100% !important;
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    text-align: center;
    max-width: 100% !important;
    & p {
        max-width: 80% !important;
        font-size: 1.5rem;
        line-height: 2.5rem;
        @media (max-width: 767px) {
            max-width: 90% !important; } }
    & h3 {
        max-width: 80% !important;
        @media (max-width: 767px) {
            max-width: 90% !important; } } }
.col-2 {
    flex: 2 0 0; }
.col-3 {
    flex: 3 0 0; }
.col-4 {
    flex: 4 0 0; }
.col-5 {
    flex: 5 0 0; }
.col-6 {
    flex: 6 0 0; }

hr {
    margin: 20px 0; }
.headline {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.3em;
    display: block;
    margin-bottom: 30px;
    padding: 5px 15px 5px 22px;
    color: $blue;
    position: relative;
    border: 1px solid $blue;
    // &:after
    //     content: ""
    //     width: 100%
    //     height: 1px
    //     background: $blue
    //     position: absolute
    //     bottom: 0
    //     left: 50%
    //     transform: translateX(-50%)
 }    //     //border-top: 2px dotted $blue
.negative {
    color: white;
    border-color: white; }


.gridContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: white;
    .sectionBTNS {
        p {
            padding: 3em 0em !important; } }
    .gridItem {
        padding: 60px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        background: #fff;
        flex: 1 0 50%;
        min-width: 280px;
        @media (max-width: 1024px) {
            flex: 1 0 100%;
            padding: 60px 40px; }
        @media (min-width: 992px) {
            min-height: 400px; }
        @media (max-width: 767px) {
            padding: 30px 20px; }
        h3 {
            text-align: center;
            max-width: 80%;
            @media (max-width: 767px) {
                text-align: center;
                max-width: 100%; } }
        p {
            font-size: 1.5rem;
            line-height: 2.5rem;
            //letter-spacing: 0.02rem
            text-align: center;

            max-width: 80%;
            @media (max-width: 767px) {
                max-width: 90%; } }
        ul {
            max-width: 80%;
            margin-left: 2.5rem; }
        img {
            max-width: 100%; }
        &.video {
            background: #cdcdcd;
            position: relative;
            overflow: hidden;
            min-height: 200px;
            height: auto;
            width: 100%;
            padding-bottom: 500px;
            background-position: center;
            @media (max-width: 768px) {
                padding-bottom: 350px; }
            @media (max-width: 568px) {
                padding-bottom: 200px; }
            video {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                max-height: 110%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%); }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                object-fit: cover;
                &.volmer {
                    height: 100%;
                    top: 60%;
                    width: auto; } } }
        &.contentLeft {
            display: flex;
            align-items: end;
            padding: 0px 40px 20px 40px !important;
            @media (min-width: 1024px) {
                padding: 60px 0px 60px 0px !important; } }
        &.contentLeft_nopadding {
            display: flex;
            align-items: end;
            padding: 0px !important;
            @media (min-width: 1024px) {
                padding: 0px !important; } }
        &.contentcenter {
            justify-content: flex-center;
            padding: 0px 40px 20px 40px !important;
            @media (min-width: 1024px) {
                padding: 60px 40px 60px 0px !important; } }
        &.contentTop_nopadding {
            justify-content: flex-center;
            padding: 0px 40px 20px 40px !important;
            @media (min-width: 1024px) {
                padding: 0px 40px 60px 40px !important; } }
        &.contentTop {
            justify-content: flex-center;
            padding: 0px 40px 20px 40px !important;
            @media (min-width: 1024px) {
                padding: 60px 40px 60px 0px !important; }
            p {
                margin: 30px 0; } }
        &#hoeren {
            background: $blue;
            p {
                color: white; } }
        &#volmer {
            background: url('../img/background-float.jpg') 0% center no-repeat;
            //background-size: cover
            animation: leftToRight 36s forwards infinite ease-in-out;
            background-size: cover !important; }
        &#standorte {
            padding: 60px;
            p {
                max-width: none;
                margin: 20px 0 30px;
                b {
                    font-size: inherit; } }

            button {
                all: unset;
                width: 50px;
                height: 50px;
                background: $blue;
                color: white;
                display: inline-block; } }
        &#karte {

            justify-content: flex-end;
            padding-bottom: 60px; }
        &#map {}


        &#team {
            flex: 2 0 100%;
            padding: 0; }
        &#optiker {
            background: #fff;
            overflow: hidden;
            display: flex;
            align-items: flex-start;
            padding: 60px;
            //height: 40vw

            p {
                margin: 1em 0em 3rem !important;
                @media (max-width: 1024px) {
                    margin: 0rem auto 3rem !important;
                    text-align: center !important; } }

            h2 {
                margin: 0 0 10px;
                @media (max-width: 1024px) {
                    margin: 1rem auto 3rem !important; } }

            .inputWrapper {
                margin-bottom: 15px;
                position: relative;
                &:after {
                    content: "OK";
                    background: $blue;
                    color: white;
                    display: inline-block;
                    width: 50px;
                    height: 100%;
                    margin: 0;
                    position: absolute;
                    right: -55px;
                    line-height: 36px;
                    text-align: center;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    top: 0; }
                &:last-of-type {
                    &:after {
                        content: "\25BC";
                        font-size: 0.825rem;
                        text-align: center;
                        margin: 0 auto;
                        line-height: 35px;
                        letter-spacing: 0; } } }
            input, select {
                all: unset;
                appearance: none;
                width: 200px;
                font-size: 1.3rem;
                padding: 5px 10px;
                border: 2px solid $blue;
                color: #666;
                position: relative;
                display: inline-block;
                background: #fff; }
            video {
                min-width: 100%;
                min-height: 100%;
                max-height: none;
                width: 100%; }
            h1 {
                color: white;
                text-transform: uppercase;
                font-size: 5rem;
                letter-spacing: 0.8rem; }
            p {
                margin: 0 0 20px;
                text-align: left; } }
        &#newsletter {
            justify-content: flex-start;
            align-items: flex-start;
            background: url(../img/portraitofveautifulcheerfulyoungbusinesswomanworkingonlaptopandlaughinginhomeoffice.jpg) left bottom;
            background-size: cover;

            h2 {
                text-align: left;
                font-size: 4rem;
                line-height: 1.1;
                letter-spacing: 0.01rem; }
            p {
                text-align: right; } }


        &#brillenSlider {
            justify-content: flex-end;
            align-items: flex-end;
            @media (max-width: 767px) {
                padding: 30px 40px 60px; }

            h2 {
                text-align: center;
                font-size: 4rem;
                line-height: 1.1;
                letter-spacing: 0.01rem;
                text-align: right;
                margin-bottom: 20px; }
            h3 {
                margin: 0 0 15px;
                color: $blue;
                font-size: 1.4em; }
            p {
                text-align: right;
                max-width: none;
                margin: 0; }
            .circles {
                margin-top: 20px;
                width: 100%;
                text-align: right;
                .circle {
                    width: 15px;
                    height: 15px;
                    background: $blue;
                    display: inline-block;
                    border-radius: 100%;
                    margin: 0 5px;
                    &.active {
                        border: 2px solid $blue;
                        background: #fff; } } }
            img {
                width: 350px;
                align-self: center; } } }
    .overlayDiv {
            div {
                position: absolute;
                width: 100%;
                background: $blue;
                padding: 1.5rem 0rem;
                bottom: 0px;
                text-align: left;
                h4 {
                    width: 83%;
                    margin: 0 auto;
                    color: #ffffff;
                    @media (max-width: 1024px) {
                        text-align: center; } } } }
    .overlayDiv2 {
            div {
                position: absolute;
                width: 100%;
                background: #333333;
                padding: 1.5rem 0rem;
                bottom: 0px;
                text-align: left;
                h4 {
                    width: 83%;
                    margin: 0 auto;
                    color: #ffffff;
                    @media (max-width: 1024px) {
                        text-align: center; } } } } }

@keyframes leftToRight {
    0%, 100% {
        background-position: 0% top;
        // background-size: 210%
        background-size: cover; }
    50% {
        background-position: 100% top;
 }        // background-size: 210%
    // 25%, 75%
 }    //     background-size: 250%
.col-45_nopadding {
    padding: 60px 40px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    background: #fff;
    flex: 1 0 50%;
    min-width: 280px;
    flex: 2 0 45% !important;
    text-align: left !important;
    align-items: flex-start !important;
    background-color: #ffffff !important;
    max-width: 100% !important;
    @media (max-width: 1024px) {
        flex: 1 0 100% !important;
        align-items: center !important; } }
.col-45 {
    padding: 60px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    background: #fff;
    flex: 1 0 50%;
    min-width: 280px;
    flex: 2 0 45% !important;
    text-align: left !important;
    align-items: flex-start !important;
    background-color: #ffffff !important;
    max-width: 100% !important;
    @media (max-width: 1024px) {
        padding: 30px 40px;
        flex: 1 0 100% !important;
        align-items: center !important; }
    & p {
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin: 0 !important;
        text-align: left !important;
        max-width: 100% !important;
        @media (max-width: 768px) {
            text-align: center !important; } }
    & h3 {
        @media (max-width: 768px) {
            text-align: center !important; } } }
.col-55 {
    flex: 2 0 55% !important;
    @media (max-width: 768px) {
        flex: 1 0 100% !important;
        padding: 0rem !important; }
    & img {
        width: 100%; } }

.sizeSlider {
    & * {
        color: $blue; }
    & img:nth-child(2) {
        margin-bottom: 2rem !important; }
    & div {
        max-width: 80%;
        @media (max-width: 480px) {
            max-width: 100%; }
        & h5 {
            max-width: 100% !important;
            text-align: left !important;
            padding-left: 12px !important; } }
    & + div {
        padding-left: 0rem !important;
        @media (max-width: 767px) {
            padding-left: 40px !important; } } }

.sizeSlider2 {
    @media (max-width: 1023px) {
        flex: 1 0 100% !important; }
    & * {
        color: $blue; }
    & div {
        width: 100%;
        & div {
            border-bottom: 2px solid black;
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                padding: 4rem 0rem 4rem 0rem;
                flex-direction: column;

                & img {
                    margin: 2rem auto 0 !important; }

                &:first-child {
                    margin-top: 0rem !important; } } }
        & div:first-child {
            img {
                margin-left: 11rem; } }
        & h5 {
            float: left; }
        & img {
            margin: 3rem 0rem 3rem 10rem; } } }
.expandSection_special {
    align-items: start;
    background-color: #cae0f7;
    padding-bottom: 0rem !important;
    * {
        text-align: left; }
    img {
        display: table;
        margin: 0 auto; }
    h1 {
        color: #015793; }
    h3 {
        color: #015793;
        font-weight: lighter; }
    p {
        color: #69696b; }

    a {
        color: white;
        background: #015793;
        padding: 1rem 1.2rem;
        margin-bottom: 40px; } }
footer {
    background: $blue;
    display: flex;
    padding: 15px 40px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
        padding: 25px 40px;
        flex: 1 0 100%;
        flex-direction: column; }
    p, a {
        color: white;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        margin: 0 10px;
        &:hover {
            color: rgba(white, 0.5); } }
    & div {
        @media (max-width: 767px) {
            text-align: center; }
        &:first-child {
            @media (max-width: 767px) {
                margin-bottom: 1rem; } } } }

#kontaktlinsen_test {
    min-height: 940px !important;
    @media (min-width: 800px) and (max-width: 1389px) {
        min-height: 940px !important; }
    @media (min-width: 1390px) {
        min-height: 650px !important; } }
.blockTool {
    flex-direction: column;
    height: auto;
    background: #fff;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    min-width: 280px;
    div {
        height: 50%;
        padding: 40px;
        justify-content: center;
        flex-direction: column;
        display: flex;
        text-align: center; } }
#marken {
    padding: 3% 0%;
    width: 90%;
    .owl-nav {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0px !important;
        width: 100%;
        height: 40px;
        button {
            position: absolute;
            &:hover {
                background: transparent !important; }
            i {
                font-size: 3rem; } }
        .owl-prev {
            left: -60px;
            position: absolute;
            width: 40px;
            height: 40px;
            border: 1px solid white;
            border-radius: 50%;
            i {
                position: relative;
                left: -3px; } }
        .owl-next {
            right: -60px;
            position: absolute;
            width: 40px;
            height: 40px;
            border: 1px solid white;
            border-radius: 50%;
            i {
                position: relative;
                right: -3px; } } }
    .item {
        img {
            width: inherit !important;
            margin: auto !important; } }
    .owl-stage {
        display: flex;
        align-items: center; } }

.markenBTN {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background: #b93121;
    font-size: 2rem !important;
    padding: 20px 20px;
    border-radius: 20px;
    display: table;
    letter-spacing: 1px;
    z-index: 10; }
.filialenMap {
    width: 100%;
    border:none !important {
        min-height: 400px; }
    @media (min-width: 1000px) {
        min-height: 700px; } }
// .mapContainer
//     &:hover
//         .overlay
//             display: none
// .overlay
//     display: block
//     background: 0 0
//     position: absolute
//     width: calc(100% - 80px)
//     height: calc(100% - 120px)
//     z-index: 999999999
.mapContainer iframe {
    pointer-events: none; }
/*CONTACT SECTION*/

.some-page-wrapper {
  background-color: red;
  flex: 1 0 100%;
  @media (min-width: 1000px) {
    flex: 1 0 50%; } }

.border_right {
    border-right: 7px solid white;
    box-sizing: border-box;
    @media (max-width: 1000px) {
        border: none; } }
.border_left {
    border-left: 7px solid white;
    box-sizing: border-box;
    @media (max-width: 1000px) {
        border: none; } }
.border_bottom {
    border-bottom: 7px solid white;
    box-sizing: border-box; }
.border_top {
    border-top: 7px solid white;
    box-sizing: border-box; }
.borderB_noneMobile {
    @media (max-width: 1000px) {
        border-bottom: none !important; } }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%; }


.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: auto;
  padding: 60px 40px;
  @media (max-width: 767px) {
    flex: 1 0 100%;
    padding: 30px 20px; }
  div {
      h4 {
        @media (max-width: 1023px) {
            text-align: center; } }
      p {
        @media (max-width: 1023px) {
            text-align: center; } }
      h4 + p {
          line-height: 25px;
          @media (max-width: 1023px) {
              text-align: center; } } } }
.datenschutz {
    h4 {
        margin-bottom: 10px; }
    .color_inherit {
        color: inherit; }
    .col-1 {
        text-align: left;
        align-items: flex-start;
        p {
            max-width: 100% !important; } } }

.cc-window {
    opacity: 1;
    transition: opacity 1s ease; }
.cc-window.cc-invisible {
    opacity: 0; }
.cc-animate.cc-revoke {
    transition: transform 1s ease; }
.cc-animate.cc-revoke.cc-top {
    -webkit-transform:translateY(-2em) {}
    transform:translateY(-2em) {} }
.cc-animate.cc-revoke.cc-bottom {
    -webkit-transform:translateY(2em) {}
    transform:translateY(2em) {} }
.cc-animate.cc-revoke.cc-active.cc-top {
    -webkit-transform:translateY(0) {}
    transform:translateY(0) {} }
.cc-animate.cc-revoke.cc-active.cc-bottom {
    -webkit-transform:translateY(0) {}
    transform:translateY(0) {} }
.cc-revoke:hover {
    -webkit-transform:translateY(0) {}
    transform:translateY(0) {} }
.cc-grower {
    max-height: 0;
    overflow:hidden {}
    -webkit-transition:max-height 1s {}
    transition:max-height 1s {} }

.cc-revoke,.cc-window {
    position:fixed {}
    overflow:hidden {}
    -webkit-box-sizing:border-box {}
    box-sizing:border-box {}
    display:-webkit-box {}
    display:-ms-flexbox {}
    display:flex {}
    -ms-flex-wrap:nowrap {}
    flex-wrap:nowrap {}
    z-index: 9999; }
.cc-window.cc-static {
    position:static {} }
.cc-window.cc-floating {
    padding: 2em;
    max-width: 24em;
    -webkit-box-orient:vertical {}
    -webkit-box-direction:normal {}
    -ms-flex-direction:column {}
    flex-direction:column {} }
.cc-window.cc-banner {
    padding: 1em 1.8em;
    width: 100%;
    -webkit-box-orient:horizontal {}
    -webkit-box-direction:normal {}
    -ms-flex-direction:row {}
    flex-direction:row {} }
.cc-revoke {
    padding: .5em; }
.cc-revoke:hover {
    text-decoration:underline {} }

.cc-btn,.cc-close,.cc-link,.cc-revoke {
    cursor:pointer {} }
.cc-link {
    opacity: .8;
    display:inline-block {}
    padding: .2em;
    text-decoration:underline {} }
.cc-link:hover {
    opacity: 1; }
.cc-link:active,.cc-link:visited {
    color:initial {} }
.cc-btn {
    display:block {}
    padding: .4em .8em;
    font-size: .9em;
    border-width: 2px;
    border-style:solid {}
    text-align:center {}
    white-space:nowrap {} }
.cc-highlight .cc-btn:first-child {
    background-color:transparent {}
    border-color:transparent {} }
.cc-highlight .cc-btn:first-child:focus,.cc-highlight .cc-btn:first-child:hover {
    background-color:transparent {}
    text-decoration:underline {} }
.cc-close {
    display:block {}
    position:absolute {}
    top: .5em;
    right: .5em;
    font-size: 1.6em;
    opacity: .9;
    line-height: .75; }
.cc-close:focus,.cc-close:hover {
    opacity: 1; }

.cc-revoke.cc-top {
    top: 0;
    left: 3em;
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em; }
.cc-revoke.cc-bottom {
    display: none;
    position: absolute;
    bottom: 1em;
    right: 1em;
    border-radius: .5em;
    padding: 0.4em 1em;
    background: $black;
    color: rgba(white, 0.6);
    font-size: 0.825rem;
    cursor: pointer; }
.cc-revoke.cc-left {
    left: 3em;
    right:unset {} }
.cc-revoke.cc-right {
    right: 3em;
    left:unset {} }
.cc-top {
    top: 1em; }
.cc-left {
    left: 1em; }
.cc-right {
    right: 1em; }
.cc-bottom {
    bottom: 1em; }
.cc-floating>.cc-link {
    margin-bottom: 1em; }
.cc-floating .cc-message {
    display:block {}
    margin-bottom: 1em; }
.cc-window.cc-floating .cc-compliance {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto; }
.cc-window.cc-banner {
    -webkit-box-align:center {}
    -ms-flex-align:center {}
    align-items:center {} }
.cc-banner.cc-top {
    left: 0;
    right: 0;
    top: 0; }
.cc-banner.cc-bottom {
    left: 0;
    right: 0;
    bottom: 0; }
.cc-banner .cc-message {
    display:block {}
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    margin-right: 1em; }
.cc-compliance {
    display:-webkit-box {}
    display:-ms-flexbox {}
    display:flex {}
    -webkit-box-align:center {}
    -ms-flex-align:center {}
    align-items:center {}
    -ms-flex-line-pack:justify {}
    align-content:space-between {} }
.cc-floating .cc-compliance>.cc-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
.cc-btn+.cc-btn {
    margin-left: .5em; }

@media print {
    .cc-revoke, .cc-window {
        display:none {} } }

// @media screen and (max-width: 900px)
//     .cc-btn
//         white-space:normal
// @media screen and (max-width:414px) and (orientation:portrait),screen and (max-width:736px) and (orientation:landscape)
//     .cc-window.cc-top
//         top: 0
//     .cc-window.cc-bottom
//         bottom: 0
//     .cc-window.cc-banner,.cc-window.cc-floating,.cc-window.cc-left,.cc-window.cc-right
//         left: 0
//         right: 0
//     .cc-window.cc-banner
//         -webkit-box-orient:vertical
//         -webkit-box-direction:normal
//         -ms-flex-direction:column
//         flex-direction:column
//     .cc-window.cc-banner .cc-compliance
//         -webkit-box-flex: 1
//         -ms-flex: 1 1 auto
//         flex: 1 1 auto
//     .cc-window.cc-floating
//         max-width:none
//     .cc-window .cc-message
//         margin-bottom: 1em
//     .cc-window.cc-banner
//         -webkit-box-align:unset
//         -ms-flex-align:unset
//         align-items:unset
//     .cc-window.cc-banner .cc-message
//         margin-right: 0


.cc-floating.cc-theme-classic {
    padding: 1.2em;
    border-radius: 5px; }
.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
    text-align:center {}
    display:inline {}
    -webkit-box-flex: 0;
    -ms-flex:none {}
    flex:none {} }
.cc-theme-classic .cc-btn {
    border-radius: 5px; }
.cc-theme-classic .cc-btn:last-child {
    min-width: 140px; }
.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
    display:inline-block {} }

.cc-theme-edgeless.cc-window {
    padding: 0; }
.cc-floating.cc-theme-edgeless .cc-message {
    margin: 2em;
    margin-bottom: 1.5em; }
.cc-banner.cc-theme-edgeless .cc-btn {
    margin: 0;
    padding: .8em 1.8em;
    height: 100%; }
.cc-banner.cc-theme-edgeless .cc-message {
    margin-left: 1em; }
.cc-floating.cc-theme-edgeless .cc-btn+.cc-btn {
    margin-left: 0; }


.cc-revoke {
    position: fixed;
    right: 1em;
    left: auto; }

.cc-window {
    color: white;
    background: #29408e;
    position: fixed;
    width: 50em!important;
    max-width: calc(100% - 2em);
    right: 2%!important;
    bottom: 2%!important;
    padding: 2em!important;
    padding-bottom: 2.5em !important;
    padding-top: 2.5em !important;
    left: auto!important;
    top: auto;
    border-radius: 4px;
    z-index: 50;
    .cookie--hinweis {
        a.cc-link {
            color: white!important;
            font-size: 1.2rem!important;
            padding: 0!important;
            text-decoration: underline;
            display: inline; }
        .cc-message {
            font-size: 1.2rem!important;
            color: white;
            margin: 0 !important;
            margin-right: 0 !important;
            display: inline; }
        .cc-compliance.cc-highlight {
            margin-top: 2em;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            .cc-btn {
                display: block;
                margin: 0 1em 0.6em;
                font-size: 1.2rem;
                cursor: pointer;
                background: white;
                text-align: center;
                border-radius: 4px;
                padding: 0.3em 1em;
                color: #29408e;
                &.cc-allow {
                    background: white;
                    border: none;
                    border-radius: 4px;
                    padding: 0.3em 1em; } } } } }
.aktionHome {
    background-position: center !important;
    min-height: 450px !important;
    @media (min-width: 480px) {
        min-height: 550px !important; }
    @media (min-width: 600px) {
        min-height: 650px !important; }
    @media (min-width: 720px) {
        min-height: 790px !important; }
    @media (min-width: 840px) and (max-width:1024px) {
        min-height: 950px !important; } }
#newsletter {
    @media (max-width: 1024px) {
        min-height: 600px; }
    @media (max-width: 767px) {
        background-position: 35% !important;
        min-height: 400px; }
    h2,a {
        @media (max-width: 1024px) {
            text-align: left !important; } }
    h2 {
        @media (max-width: 1024px) {
            margin: 0px !important; }
        @media (max-width: 480px) {
            font-size: 30px !important; } }
    a {
        @media (max-width: 1024px) {
            margin: 20px 0px !important; }
        @media (max-width: 480px) {
            font-size: 10px !important; } } }
.btn-bewerten {
    font-size: 16px;
    color: #29408e !important;
    background: white;
    margin-top: 25px;
    display: table;
    padding: 10px;
    @media (max-width: 1023px) {
        margin: 25px auto 0px !important; }
    &::before {
        content: "";
        width: 16px;
        height: 16px;
        background: url("/assets/img/Google-Reviews-icon_blue.png");
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        top: 2px; } }
.btn-bewerten-single {
    font-size: 16px;
    color: white !important;
    background: #29408e;
    margin-top: 25px;
    display: table;
    padding: 10px;
    margin: 25px auto !important;
    @media (max-width: 1023px) {
        margin: 25px auto 25px !important; }
    &::before {
        content: "";
        width: 16px;
        height: 16px;
        background: url("/assets/img/Google-Reviews-icon.png");
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        position: relative;
        display: inline-block;
        margin-right: 5px;
        top: 2px; } }
.reviewContainer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    max-width: 500px;
    margin: 20px auto 0px;
    p {
        max-width: 100% !important;
        padding: 0px 15px; }
    .google {
        color: white;
        background: #29408e;
        text-align: center;
        padding: 15px;
        font-size: 16px;
        font-weight: bold; }
    img {
        max-width: 100%; } }
.actionhome {
    @media(max-width: 1024px) {
        padding: 50px 0px 50px 0px !important;
        min-height: 66vw !important; }
    @media(max-width: 767px) {
        min-height: 73vw !important; } }
.h1seo {
    font-size: 3em !important;
    margin-bottom: 30px;
    text-align: center !important; }
.stoererBaterie {
    background: white;
    color: #29408e;
    width: 20vw;
    height: 20vw;
    max-width: 180px;
    max-height: 180px;
    border-radius: 50%;
    position: absolute;
    top: 50px;
    left: 2vw;
    transform: rotate(-15deg);
    display: flex;
    font-size: 18px;
    padding: 20px;
    align-items: center;
    text-align: center;
    line-height: 23px;
    @media (max-width: 1200px) {
        max-width: 160px;
        max-height: 160px;
        font-size: 16px !important;
        line-height: 18px; }
    @media (max-width: 1024px) {
        left: 10vw; }
    @media (max-width: 767px) {
        max-width: 180px;
        max-height: 180px;
        width: 150px !important;
        height: 150px !important;
        font-size: 14px !important;
        top: 80px !important; }
    @media (max-width: 480px) {
        max-height: 120px !important;
        max-width: 120px !important;
        font-size: 11.1px !important;
        line-height: 14px !important;
        top: 90px !important; }
    a {
        position: absolute;
        top: 0px;
        height: 0px;
        width: 100%;
        height: 100%; } }
#hoeren {
    .headline.negative {
        @media (max-width: 767px) {
            margin-bottom: 150px; }
        @media (max-width: 480px) {
            margin-bottom: 170px !important; } } }
.actionhome {
    padding: 0px !important;
    background-image: none !important;
    background-size: cover !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    justify-content: end !important;
    @media (max-width: 1024px) {
        background-image: none !important;
        order: 2; } }

.aktionJens_volmer_img {
    opacity: 1 !important;
    transform: none !important;
    top: 0px !important;
    left: 0px !important;
    width: 100% !important;
    position: relative !important;
    @media (max-width: 1024px) {
        display: block; } }
.aktionJens_volmer_img + p {
    position: absolute;
    left: 3vw;
    top: 2vw;
    width: 60%;
    color: #29408e !important;
    font-size: 2.5rem !important;
    line-height: 3.5rem !important;
    text-align: left !important;
    @media (max-width: 1400px) {
        width: 80%; }
    @media (max-width: 1024px) {
        top: 5vw;
        left: 5vw; }
    @media (max-width: 768px) {
        font-size: 1.8rem !important;
        line-height: 2.3rem !important;
        width: 100%;
        text-align: center !important; }
    * {
        color: #29408e !important;
        font-size: 2.5rem !important;
        line-height: 3.5rem !important;
        @media (max-width: 768px) {
            font-size: 1.8rem !important;
            line-height: 2.3rem !important; } } }
.jensvolmer-action-Textsmall {
    position: absolute;
    color: black !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 40% !important;
    text-align: right !important;
    left: 3vw;
    @media (max-width: 1300px) {
        font-size: 1.3rem !important;
        line-height: 1.5rem !important;
        transform: translateY(-30%) !important;
        top: 30%; }
    @media (max-width: 1024px) {
        top: 50%;
        max-width: 200px !important;
        left: calc(30vw - 40px); }
    @media (max-width: 767px) {
        left: calc(30vw - 100px); }
    b {
        font-size: 1.3rem !important; } }
.flexDiv {
    display: flex;
    min-width: 100%;
    flex-wrap: wrap; }
.text-start-align strong {
    font-size: 1.6rem !important; }

#TeamSlider {
    img {
        max-width: 150px;
        margin: auto; }
    p {
        margin: 20px auto;
        i {
            font-size: 16px; } } }

.lilaText {
    color: #29408e !important; }
